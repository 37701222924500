/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_badge.scss
*/

@import '../functions/unit-transform';

.badge {
  line-height: rem(15px);
  vertical-align: middle;
  box-shadow: none;
}

a.badge {
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
