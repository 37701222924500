@import '../colors';
@import '../theme';
@import '../z-index-hierarchy';
@import '../functions/unit-transform';

.dxt-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: rem(16px) 0;
  position: absolute;
  z-index: $z-index-normal;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: rem(8px);
  height: rem(8px);
  border-radius: rem(50px);
  background-color: _get-color(light, on-primary, text-color);
  color: _get-color(light, on-primary, text-color);

  @include use-box-shadow-color(9999px 0 0 -5px, on-primary, text-color);

  animation: dotpulse 1.5s infinite linear;
  animation-delay: 0.25s;

  &::before {
    @include use-box-shadow-color(9984px 0 0 -5px, on-primary, text-color);

    animation: dotpulsebefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    @include use-box-shadow-color(10014px 0 0 -5px, on-primary, text-color);

    animation: dotpulseafter 1.5s infinite linear;
    animation-delay: 0.5s;
  }
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: rem(8px);
  height: rem(8px);
  border-radius: rem(5px);
  background-color: _get-color(light, on-primary, text-color);
  color: _get-color(light, on-primary, text-color);
}

@keyframes dotpulsebefore {
  0% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #0000006b;
  }

  30% {
    box-shadow: rem(9984px) 0 0 rem(2px) #0000006b;
  }

  60%,
  100% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #0000006b;
  }
}

@keyframes dotpulse {
  0% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #000;
  }

  30% {
    box-shadow: rem(9999px) 0 0 rem(2px) #000;
  }

  60%,
  100% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #000;
  }
}

@keyframes dotpulseafter {
  0% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #0000006b;
  }

  30% {
    box-shadow: rem(10014px) 0 0 rem(2px) #0000006b;
  }

  60%,
  100% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #0000006b;
  }
}

@keyframes dotpulsebeforedark {
  0% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(9984px) 0 0 rem(2px) #ffffff6b;
  }

  60%,
  100% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #ffffff6b;
  }
}

@keyframes dotpulsedark {
  0% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(9999px) 0 0 rem(2px) #fff;
  }

  60%,
  100% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #fff;
  }
}

@keyframes dotpulseafterdark {
  0% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(10014px) 0 0 rem(2px) #ffffff6b;
  }

  60%,
  100% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #ffffff6b;
  }
}

// DARK THEME
body.dark-theme {
  .dot-pulse {
    background-color: _get-color(dark-theme, on-primary, text-color);
    color: _get-color(dark-theme, on-primary, text-color);
    animation: dotpulsedark 1.5s infinite linear;
    animation-delay: 0.25s;

    &::before,
    &::after {
      background-color: _get-color(dark-theme, on-primary, text-color);
      color: _get-color(dark-theme, on-primary, text-color);
    }

    &::before {
      animation: dotpulsebeforedark 1.5s infinite linear;
      animation-delay: 0s;
    }

    &::after {
      animation: dotpulseafterdark 1.5s infinite linear;
      animation-delay: 0.5s;
    }
  }
}
