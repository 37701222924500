/* COLORS */

/* DEXT Primary */
$color-dext-primary-100: #00b8d8;
$color-dext-primary-75: #00b8d875;
$color-dext-primary-50: #00b8d850;
$color-dext-primary-25: #00b8d825;
$color-dext-primary-10: #00b8d810;

/* DEXT Contrast */
$color-dext-contrast-100: #262c43;
$color-dext-contrast-75: #262c4375;
$color-dext-contrast-50: #262c4350;
$color-dext-contrast-25: #262c4325;
$color-dext-contrast-10: #262c4310;
$color-dext-contrast-dark: #142028;

/* DARK Background */
$color-dark-background-100: #020416;
$color-dark-background-75: #02041675;
$color-dark-background-50: #02041650;
$color-dark-background-25: #02041625;
$color-dark-background-10: #02041610;
$color-dark-background-medium: #17272f;
$color-dark-background-medium-light: #23323c;
$color-dark-background-light: #33414b;
$color-dark-background-lighter: #445663;

/* BLUE Background */
$color-blue-background-100: #2e4f6a;
$color-blue-background-75: #2e4f6a75;
$color-blue-background-50: #2e4f6a50;

/* WHITE Background */
$color-white-background-100: #fff;

/* Blacks & whites */
$color-neutral-black: #111;
$color-neutral-100: #111;
$color-neutral-80: #414141;
$color-neutral-60: #707070;
$color-neutral-40: #a0a0a0;
$color-neutral-20: #cfcfcf;
$color-neutral-10: #e7e7e7;
$color-neutral-05: #f3f3f3;
$color-background-white: #fff;
$color-background-white-transparency: #ffffff1f;

/* Light */
$color-light-medium: #687a88;
$color-light-light: #cacedb;

/* TRANSACTIONS */
$buy-color: #17c671;
$buy-color-dark: #00cb4475;
$buy-color-light: #ffacb1;
$sell-color: #ea3943;
$sell-color-dark: #c84c5175;
$sell-color-light: #cdffe7;
$remove-color: #b36e20;
$remove-color-dark: #ffdbad;
$add-color: #185671;
$add-color-dark: #b2e1ff;
$success-color: #17c671;
$danger-color: #ea3943;
$warning-color: #ffb400;
$hot-color: #f8b60f;
$dark-contrast-app: #152028;

/* Colors Design Dark */
$dark-body-bg-color: #0b1217;
$dark-bg-color: #142028;
$dark-bg-over-color: #23323c;
$dark-bg-gray-color: #3a4956;
$dark-gray-color: #818ea3;
$dark-softgray-color: #cacedb;
$dark-text: #1c2127;

/* Colors Design light */

$light-bg-color: #f4f5f6;
$light-bg-over-color: #e2e7ec;
$light-text-color: #142028;
$light-gray-color: #3d5170;
$light-softgray-color: #cacedb;
$light-buy-color: #17c671;
$warning-alert-color: #ffdf91;
$light-warning-color: #ffc762;
$light-sell-color: #ff868d;
$danger-alert-color: #ff868d;
$warning-alert-light-color: #eda803;

/* User Account */

$user-account-free-color: #23323c;
$user-account-standard-color: #00b8d8;
$user-account-premium-color: #efb90a;
