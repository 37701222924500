/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_transitions.scss
*/

.fade {
  transition: opacity 0.2s ease-in-out;
}

.collapsing {
  transition: height 350ms ease-in-out;
}
