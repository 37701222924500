/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_dropdown.scss
*/
@import '../theme';
@import '../functions/unit-transform';

.dropdown-header {
  padding: 0;
  font-size: rem(11px);
  color: #868e96;
}

.custom-select.dropdown-lg {
  height: rem(36px);
  border-radius: rem(10px);
  color: _get-color(light, on-primary, text-color);
}

// DARK THEME
body.dark-theme {
  color: _get-color(dark-theme, on-primary, text-color);
}
