/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_reboot.scss
*/

@import '../functions/unit-transform';

p {
  margin-bottom: rem(28px);
}

h1 {
  font-size: rem(32px);
}

h3 {
  font-size: rem(24px);
}
