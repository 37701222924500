@import './typo-vars';
@import './colors';

$font-family-base: 'Poppins', 'Roboto', sans-serif !default;

// Titles size
$h1-font-size: 32px !default;
$h2-font-size: 24px !default;
$h3-font-size: 24px !default;
$h4-font-size: 20px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

// Tables
$table-cell-padding-y: 0;
$table-cell-padding-x: 0;

// Badges
$badge-font-size: 11px !default;
$badge-font-weight: $_font-weight-medium !default;
$badge-padding-y: 3px !default;
$badge-padding-x: 7px !default;
$badge-border-radius: 5px;

// Checkboxes
$form-check-input-border: none;
$form-check-input-focus-box-shadow: none;

// Colors
$primary: $color-dext-primary-100 !default;
$success: $success-color !default;
$danger: $danger-color !default;
$warning: $warning-color !default;
$secondary: $dark-gray-color !default;
