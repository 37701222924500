/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_tooltip.scss
*/

@import '../theme';
@import '../typo-vars';
@import '../z-index-hierarchy';
@import '../functions/unit-transform';

.tooltip {
  z-index: #{$z-index-highest} + 6 !important;
  position: fixed;
  margin: 0;
  font-family:
    'Poppins',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    sans-serif;
  font-style: normal;
  font-weight: $_font-weight-regular;
  font-size: rem(11px);
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  border-radius: rem(10px);

  @media screen and (max-width: 768px) {
    max-width: rem(200px);
  }

  .tooltip-arrow {
    display: none;
    width: rem(5px);
    height: rem(5px);
  }

  > .tooltip-inner {
    background-color: #f4f5f6;
    color: _get-color(light, on-primary, text-color);
    box-shadow: none !important;
  }

  &.show {
    opacity: 1;
    background-color: #f4f5f6;
  }

  &.opacity-1 {
    > .tooltip-inner {
      opacity: 0.4;
    }
  }

  &.opacity-3 {
    > .tooltip-inner {
      opacity: 1;
    }
  }

  &.disabled-tooltip {
    > .tooltip-inner {
      color: #818ea3cc;
    }
  }
}

.show.tooltip {
  z-index: $z-index-highest;
}

.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: rem(5px) 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-width: rem(5px) rem(2.5px) 0;
  border-top-color: #fff;
}

.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 rem(5px);
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  width: rem(5px);
  height: rem(5px);
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-width: rem(2.5px) rem(5px) rem(2.5px) 0;
  border-right-color: #fff;
}

.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: rem(5px) 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-width: 0 rem(2.5px) rem(5px);
  border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 rem(5px);
}

.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  width: rem(5px);
  height: rem(5px);
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-width: rem(2.5px) 0 rem(2.5px) rem(5px);
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: rem(200px);
  padding: rem(7px) rem(13px);
  color: #5a6169;
  background-color: #fff;
  box-shadow:
    0 rem(3px) rem(15px) rgb(90 97 105 / 10%),
    0 rem(2px) rem(3px) rgb(90 97 105 / 20%);
  border-radius: rem(6px);
}

.valid-tooltip {
  background-color: rgb(23 198 113 / 80%);
}

.invalid-tooltip {
  background-color: rgb(196 24 60 / 80%);
}

// DARK THEME
body.dark-theme {
  .tooltip {
    > .tooltip-inner {
      background-color: _get-color(dark-theme, primary, body-background);
      color: _get-color(dark-theme, on-primary, text-color);
    }

    &.show {
      background-color: _get-color(dark-theme, primary, body-background);
    }

    &.disabled-tooltip {
      > .tooltip-inner {
        color: #818ea3cc;
      }
    }
  }
}
