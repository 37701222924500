/*
IMPORTANT: some font-faces in this file are commented on purpose so that their corresponding font file won't be downloaded by the browser.
In that case, when there is a font-style without a matching font-face, the browser will simulate it using the most similar font-face available.
This is to reduce the amount of font files downloaded by the browser, only those that are strictly needed will be downloaded.
*/

/*
IMPORTANT: using font-display `swap` to temporarily show a system font while the custom font loads.
That way we avoid the so called "flash of invisible text" (FOIT).
See: https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display
 */
@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Regular.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-Italic.woff2') format('truetype');
//  font-weight: 400;
//  font-style: italic;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-Thin.woff2') format('truetype');
//  font-weight: 100;
//  font-style: normal;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-ThinItalic.woff2') format('truetype');
//  font-weight: 100;
//  font-style: italic;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-ExtraLight.woff2') format('truetype');
//  font-weight: 200;
//  font-style: normal;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-ExtraLightItalic.woff2') format('truetype');
//  font-weight: 200;
//  font-style: italic;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-Light.woff2') format('truetype');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
// }

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-LightItalic.woff2') format('truetype');
//  font-weight: 300;
//  font-style: italic;
//  font-display: swap;
// }

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Medium.woff2') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-MediumItalic.woff2') format('truetype');
//  font-weight: 500;
//  font-style: italic;
//  font-display: swap;
// }

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-SemiBold.woff2') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-SemiBoldItalic.woff2') format('truetype');
//  font-weight: 600;
//  font-style: italic;
//  font-display: swap;
// }

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Bold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-BoldItalic.woff2') format('truetype');
//  font-weight: 700;
//  font-style: italic;
//  font-display: swap;
// }

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-ExtraBold.woff2') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-ExtraBoldItalic.woff2') format('truetype');
//  font-weight: 800;
//  font-style: italic;
//  font-display: swap;
// }

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Black.woff2') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//  font-family: 'Poppins';
//  src: url('../../assets/fonts/Poppins-BlackItalic.woff2') format('truetype');
//  font-weight: 900;
//  font-style: italic;
//  font-display: swap;
// }
