@import 'ui/src/assets/styles/functions/unit-transform';
@import 'ui/src/assets/styles/colors';

.liquidity-lock-tooltip {
  .tippy-box {
    max-width: unset !important;
  }
}

.tippy-box {
  @include use-text-color(on-primary, text-color);
  @include use-background(primary, body-background);

  box-shadow:
    0 rem(2px) rem(4px) rem(-1px) rgb(0 0 0 / 20%),
    0 rem(4px) rem(5px) 0 rgb(0 0 0 / 14%),
    0 rem(1px) rem(10px) 0 rgb(0 0 0 / 12%);
  max-width: rem(200px) !important;
  border-radius: rem(6px);
  font-size: rem(11px);
  line-height: 1.5;
  text-align: center;
  padding: rem(10px);
  pointer-events: all;
  word-wrap: break-word;
}

.tippy-variation-undefined {
  z-index: 100000000 !important;

  &.disabled-tooltip {
    .tippy-box {
      color: #818ea3cc !important;
    }
  }
}

// DARK
body.dark-theme {
  .tippy-box {
    border: rem(1px) solid $dark-gray-color;
    border-radius: rem(6px);
  }
}
