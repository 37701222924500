/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/utilities/_text.scss
*/
@import '../colors';

.text-muted {
  color: $dark-gray-color !important;
}

.text-primary {
  color: $color-dext-primary-100 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: $dark-gray-color !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #42484e !important;
}

.text-success {
  color: $success-color !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #129857 !important;
}

.text-info {
  color: $color-dext-primary-100 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #008da5 !important;
}

.text-warning {
  color: #ffb400 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #cc9000 !important;
}

.text-danger {
  color: #c4183c !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #97122e !important;
}

.text-light {
  color: $dark-gray-color !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #e2e2e2 !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #0a0c0d !important;
}

a.text-white:focus,
a.text-white:hover {
  color: #e6e6e6 !important;
}

.text-black {
  color: #000;
}

a.text-black:focus,
a.text-black:hover {
  color: #000 !important;
}

.text-purple {
  color: #800080ff !important;

  &:hover {
    color: #800080ff !important;
  }
}

.text-blue {
  color: #00ff !important;

  &:hover {
    color: #00ff !important;
  }
}

.text-darkgreen {
  color: #006400ff !important;

  &:hover {
    color: #006400ff !important;
  }
}

.text-darkgoldenrod {
  color: #b8860bff !important;

  &:hover {
    color: #b8860bff !important;
  }
}

.text-darkblue {
  color: #00008bff !important;

  &:hover {
    color: #00008bff !important;
  }
}

.text-brown {
  color: #a52a2aff !important;

  &:hover {
    color: #a52a2aff !important;
  }
}

.text-saddlebrown {
  color: #8b4513ff !important;

  &:hover {
    color: #8b4513ff !important;
  }
}

.text-palegreen {
  color: #98fb98ff !important;

  &:hover {
    color: #98fb98ff !important;
  }
}

.text-salmon {
  color: #fa8072ff !important;

  &:hover {
    color: #fa8072ff !important;
  }
}

.text-royal-blue {
  color: #674eec !important;

  &:hover {
    color: #674eec !important;
  }
}
