/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_close.scss
*/
@import '../theme';
@import '../typo-vars';
@import '../colors';
@import '../functions/unit-transform';

.close {
  background: transparent;
  color: _get-color(light, on-primary, text-muted-color);
  font-size: $_font-size-xl;
  font-weight: $_font-weight-medium;
  text-shadow: none;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border: none;
  opacity: 1;
  padding: 0;

  fa-icon {
    height: rem(28px);
    width: rem(28px);
    background-color: _get-color(light, primary, sub-content-background);
    color: _get-color(light, on-primary, text-muted-color);
    border-radius: rem(50px);
    font-size: rem(19px);
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.close:focus {
  outline: none;
}

.close:hover {
  opacity: 1 !important;

  fa-icon {
    color: _get-color(light, on-primary, text-color);
  }
}

// DARK THEME
body.dark-theme {
  .close {
    color: _get-color(dark-theme, on-primary, text-muted-color);

    fa-icon {
      background-color: _get-color(dark-theme, primary, sub-content-background);
    }
  }

  .close:hover {
    opacity: 1 !important;

    fa-icon {
      color: #fff !important;
    }
  }
}
