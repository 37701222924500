/* stylelint-disable declaration-property-value-no-unknown */
@use 'sass:map';
@import '../typo-vars';

/* Mixins */

/* Private mixin to get selected font */
@mixin get-font($size, $weight) {
  @if $size == 'mega' {
    @include font-mega($weight);
  } @else if $size == 'xxl' {
    @include font-xxl($weight);
  } @else if $size == 'xl' {
    @include font-xl($weight);
  } @else if $size == 'l' {
    @include font-l($weight);
  } @else if $size == 'm' {
    @include font-m($weight);
  } @else if $size == 's' {
    @include font-s($weight);
  } @else if $size == 'xs' {
    @include font-xs($weight);
  } @else if $size == 'xxs' {
    @include font-xxs($weight);
  } @else {
    @error 'Selected Font size does not exist';
  }
}

/* XXL Mixin */
@mixin font-mega($weight) {
  @if $weight == 'bold' {
    @include font-mega-bold;
  } @else if $weight == 'medium' {
    @include font-mega-medium;
  } @else if $weight == 'regular' {
    @include font-mega-regular;
  } @else if $weight == 'light' {
    @include font-mega-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* XXL Mixin */
@mixin font-xxl($weight) {
  @if $weight == 'bold' {
    @include font-xxl-bold;
  } @else if $weight == 'medium' {
    @include font-xxl-medium;
  } @else if $weight == 'regular' {
    @include font-xxl-regular;
  } @else if $weight == 'light' {
    @include font-xxl-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* XL Mixin */
@mixin font-xl($weight) {
  @if $weight == 'bold' {
    @include font-xl-bold;
  } @else if $weight == 'medium' {
    @include font-xl-medium;
  } @else if $weight == 'regular' {
    @include font-xl-regular;
  } @else if $weight == 'light' {
    @include font-xl-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* L Mixin */
@mixin font-l($weight) {
  @if $weight == 'bold' {
    @include font-l-bold;
  } @else if $weight == 'medium' {
    @include font-l-medium;
  } @else if $weight == 'regular' {
    @include font-l-regular;
  } @else if $weight == 'light' {
    @include font-l-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* M Mixin */
@mixin font-m($weight) {
  @if $weight == 'bold' {
    @include font-m-bold;
  } @else if $weight == 'medium' {
    @include font-m-medium;
  } @else if $weight == 'regular' {
    @include font-m-regular;
  } @else if $weight == 'light' {
    @include font-m-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* S Mixin */
@mixin font-s($weight) {
  @if $weight == 'bold' {
    @include font-s-bold;
  } @else if $weight == 'medium' {
    @include font-s-medium;
  } @else if $weight == 'regular' {
    @include font-s-regular;
  } @else if $weight == 'light' {
    @include font-s-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* XS Mixin */
@mixin font-xs($weight) {
  @if $weight == 'bold' {
    @include font-xs-bold;
  } @else if $weight == 'medium' {
    @include font-xs-medium;
  } @else if $weight == 'regular' {
    @include font-xs-regular;
  } @else if $weight == 'light' {
    @include font-xs-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* XXS Mixin */
@mixin font-xxs($weight) {
  @if $weight == 'bold' {
    @include font-xxs-bold;
  } @else if $weight == 'medium' {
    @include font-xxs-medium;
  } @else if $weight == 'regular' {
    @include font-xxs-regular;
  } @else if $weight == 'light' {
    @include font-xxs-light;
  } @else {
    @error 'Selected Font weight does not exist';
  }
}

/* Mega Fonts */
@mixin font-mega-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, mega);
  line-height: map.get($_line-height-types, mega);
}

@mixin font-mega-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, mega);
  line-height: map.get($_line-height-types, mega);
}

@mixin font-mega-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, mega);
  line-height: map.get($_line-height-types, mega);
}

@mixin font-mega-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, mega);
  line-height: map.get($_line-height-types, mega);
}

/* XXL Fonts */
@mixin font-xxl-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, xxl);
  line-height: map.get($_line-height-types, xxl);
}

@mixin font-xxl-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, xxl);
  line-height: map.get($_line-height-types, xxl);
}

@mixin font-xxl-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, xxl);
  line-height: map.get($_line-height-types, xxl);
}

@mixin font-xxl-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, xxl);
  line-height: map.get($_line-height-types, xxl);
}

/* XL Fonts */
@mixin font-xl-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, xl);
  line-height: map.get($_line-height-types, xl);
}

@mixin font-xl-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, xl);
  line-height: map.get($_line-height-types, xl);
}

@mixin font-xl-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, xl);
  line-height: map.get($_line-height-types, xl);
}

@mixin font-xl-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, xl);
  line-height: map.get($_line-height-types, xl);
}

/* L Fonts */
@mixin font-l-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, l);
  line-height: map.get($_line-height-types, l);
}

@mixin font-l-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, l);
  line-height: map.get($_line-height-types, l);
}

@mixin font-l-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, l);
  line-height: map.get($_line-height-types, l);
}

@mixin font-l-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, l);
  line-height: map.get($_line-height-types, l);
}

/* M Fonts */
@mixin font-m-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, m);
  line-height: map.get($_line-height-types, m);
}

@mixin font-m-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, m);
  line-height: map.get($_line-height-types, m);
}

@mixin font-m-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, m);
  line-height: map.get($_line-height-types, m);
}

@mixin font-m-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, m);
  line-height: map.get($_line-height-types, m);
}

/* S Fonts */
@mixin font-s-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, s);
  line-height: map.get($_line-height-types, s);
}

@mixin font-s-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, s);
  line-height: map.get($_line-height-types, s);
}

@mixin font-s-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, s);
  line-height: map.get($_line-height-types, s);
}

@mixin font-s-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, s);
  line-height: map.get($_line-height-types, s);
}

/* XS Fonts */
@mixin font-xs-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, xs);
  line-height: map.get($_line-height-types, xs);
}

@mixin font-xs-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, xs);
  line-height: map.get($_line-height-types, xs);
}

@mixin font-xs-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, xs);
  line-height: map.get($_line-height-types, xs);
}

@mixin font-xs-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, xs);
  line-height: map.get($_line-height-types, xs);
}

/* XXS Fonts */
@mixin font-xxs-bold {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, bold);
  font-size: map.get($_font-size-types, xxs);
  line-height: map.get($_line-height-types, xxs);
}

@mixin font-xxs-medium {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, medium);
  font-size: map.get($_font-size-types, xxs);
  line-height: map.get($_line-height-types, xxs);
}

@mixin font-xxs-regular {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, regular);
  font-size: map.get($_font-size-types, xxs);
  line-height: map.get($_line-height-types, xxs);
}

@mixin font-xxs-light {
  @include font-style-normal;

  font-weight: map.get($_font-weight-types, light);
  font-size: map.get($_font-size-types, xxs);
  line-height: map.get($_line-height-types, xxs);
}

/* Default Font Type */
@mixin font-style-normal {
  font-family: $_font-family;
  font-style: $_font-style;
  -moz-osx-font-smoothing: grayscale;
}
