@charset "UTF-8";

/*
These styles are originally in node_modules/@siemens/ngx-datatable/assets/icons.css

IMPORTANT: this is an exact copy of those but with just two additions in the font-face difinition:
- `font-display: swap`: to instruct the browser to temporarily show a system font while the custom font loads in order to avoid the "flash of invisible text" (FOIT)
- font url: because we cannot reference those from the node_modules/@swimlane/ngx-datatable package, we had to copy those font files to /assets
*/

@font-face {
  font-family: 'data-table';
  src: url('../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.eot');
  src:
    url('../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.eot?#iefix') format('embedded-opentype'),
    url('../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.woff') format('woff'),
    url('../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.ttf') format('truetype'),
    url('../../../node_modules/@siemens/ngx-datatable/assets/fonts/data-table.svg#data-table') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]::before {
  font-family: 'data-table', sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='datatable-icon-']::before,
[class*=' datatable-icon-']::before {
  font-family: 'data-table', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter::before {
  content: '\62';
}

.datatable-icon-collapse::before {
  content: '\61';
}

.datatable-icon-expand::before {
  content: '\63';
}

.datatable-icon-close::before {
  content: '\64';
}

.datatable-icon-up::before {
  content: '\65';
}

.datatable-icon-down::before {
  content: '\66';
}

.datatable-icon-sort-unset::before {
  content: '\63';
  opacity: 0.5;
}

.datatable-icon-sort::before {
  content: '\67';
}

.datatable-icon-done::before {
  content: '\68';
}

.datatable-icon-done-all::before {
  content: '\69';
}

.datatable-icon-search::before {
  content: '\6a';
}

.datatable-icon-pin::before {
  content: '\6b';
}

.datatable-icon-add::before {
  content: '\6d';
}

.datatable-icon-left::before {
  content: '\6f';
}

.datatable-icon-right::before {
  content: '\70';
}

.datatable-icon-skip::before {
  content: '\71';
}

.datatable-icon-prev::before {
  content: '\72';
}
