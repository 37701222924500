@import 'ui/src/assets/styles/theme';
@import 'ui/src/assets/styles/font-size';

.sevioads {
  .coinzilla-banner-container {
    border-radius: rem(10px);
    padding: rem(5px) rem(10px);
    width: 100%;

    @include use-background(primary, sub-content-background);

    display: flex;
    gap: rem(4px);
    align-items: center;

    &:hover {
      @include use-background(primary, gray-background);
    }

    .info-container {
      display: flex;
      flex-direction: column;
      gap: rem(4px);
      width: 100%;

      .info {
        display: flex;
        gap: rem(4px);
        justify-content: space-between;
        width: 100%;

        > div {
          display: flex;
          align-items: center;
          gap: rem(8px);
          flex-wrap: wrap;

          img {
            height: rem(30px);
            width: rem(30px);
            border-radius: rem(50px);
          }

          .title {
            @include use-text-color(on-primary, text-color);

            font-weight: $_font-weight-medium;
            font-size: $_font-size-xs;
          }

          .description {
            @include use-text-color(on-primary, soft-gray-color);

            font-size: $_font-size-xs;
          }

          .cta-button {
            @include use-text-color(on-primary, text-color);

            margin-left: rem(5px);
            font-weight: $_font-weight-medium;
            font-size: $_font-size-xs;
          }
        }
      }

      .description-mobile-container {
        display: flex;
        align-items: center;
        gap: rem(4px);
        width: 100%;
        flex-wrap: wrap;

        .description {
          @include use-text-color(on-primary, soft-gray-color);

          font-size: $_font-size-xs;
        }

        .cta-button {
          @include use-text-color(on-primary, text-color);

          margin-left: rem(5px);
          font-weight: $_font-weight-medium;
          font-size: $_font-size-xs;
        }
      }
    }

    .header-container {
      display: flex;
      align-items: center;
      gap: rem(14px);

      img {
        height: rem(30px);
        width: rem(30px);
        border-radius: rem(50px);
      }

      .title {
        font-size: $_font-size-xs;

        @include use-text-color(on-primary, text-color);

        font-weight: $_font-weight-medium;
      }
    }

    .body-container {
      display: flex;

      .description {
        @include use-text-color(on-primary, soft-gray-color);

        font-size: $_font-size-xs;
        margin-right: rem(4px);
      }

      .cta-button {
        @include use-text-color(on-primary, text-color);

        margin-left: rem(5px);
        font-weight: $_font-weight-medium;
        font-size: $_font-size-xs;
      }
    }
  }
}

.side-banner {
  .coinzilla-banner-container {
    align-items: flex-start !important;
    flex-direction: column;
  }
}
