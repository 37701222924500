@import '../typo-vars';
@import '../functions/unit-transform';

.progress-wrapper {
  position: relative;
  color: #5a6169;
}

.progress-wrapper .progress-label {
  font-size: $_font-size-xs;
}

.progress-wrapper .progress-value {
  position: absolute;
  top: rem(6px);
  right: 0;
  color: #5a6169;
}

.progress {
  height: rem(10px);
  font-size: rem(10px);
  line-height: rem(10px);
  background-color: #f5f5f6;
  margin-top: rem(6px);
  border-radius: rem(20px);
  box-shadow: inset 0 rem(1.6px) rem(1.6px) rgb(90 97 105 / 15%);
}

.progress-sm {
  height: rem(5px);
}

.progress-lg {
  height: rem(15px);
}

.progress-lg .progress-bar {
  height: rem(15px);
}

.progress-bar {
  height: rem(10px);
  line-height: rem(10px);
  color: #fff;
  background-color: #00b8d8;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgb(255 255 255 / 15%) 25%,
    transparent 25%,
    transparent 50%,
    rgb(255 255 255 / 15%) 50%,
    rgb(255 255 255 / 15%) 75%,
    transparent 75%,
    transparent
  );
  background-size: rem(10px) rem(10px);
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
