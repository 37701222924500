@use 'sass:map';
/* stylelint-disable-next-line scss/load-no-partial-leading-underscore */
@import './functions/_color-themes';

/* Get color theme */
@mixin use-text-color($color, $type: null, $important: false, $opacity: 1) {
  color: rgba(_get-color(light, $color, $type), $opacity);

  @each $theme in map.keys($_themes) {
    body.#{$theme} :host-context & {
      color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
    body.#{$theme} & {
      color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
  }
}

/* Get background color */
@mixin use-background($color, $type: null, $important: false, $opacity: 1) {
  background-color: rgba(_get-color(light, $color, $type), $opacity) _get-important($important);

  @each $theme in map.keys($_themes) {
    body.#{$theme} :host-context & {
      background-color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
    body.#{$theme} & {
      background-color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
  }
}

/* Get box-shadow color */
@mixin use-box-shadow-color($box-options, $color, $type: null, $important: false, $opacity: 1) {
  $color-light: rgba(_get-color(light, $color, $type), $opacity);
  $get-important: _get-important($important);

  box-shadow: $box-options $color-light $get-important;

  @each $theme in map.keys($_themes) {
    $color-theme: rgba(_get-color($theme, $color, $type), $opacity);
    body.#{$theme} :host-context & {
      box-shadow: $box-options $color-theme $get-important;
    }
    body.#{$theme} & {
      box-shadow: $box-options $color-theme $get-important;
    }
  }
}

/* Get border-color color */
@mixin use-border-color($color, $type: null, $important: false, $opacity: 1) {
  border-color: rgba(_get-color(light, $color, $type), $opacity) _get-important($important);

  @each $theme in map.keys($_themes) {
    body.#{$theme} :host-context & {
      border-color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
    body.#{$theme} & {
      border-color: rgba(_get-color($theme, $color, $type), $opacity) _get-important($important);
    }
  }
}
