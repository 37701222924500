/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/utilities/_borders.scss
*/

@import '../functions/unit-transform';

.border-top {
  border-top: rem(1px) solid #e1e5eb !important;
}

.border-bottom {
  border-bottom: rem(1px) solid #e1e5eb !important;
}

.rounded {
  border-radius: rem(5px) !important;
}
