@import '../chain-colors';

@mixin chains-background-color {
  @each $name, $value in $chain-colors {
    .#{$name} {
      background-color: rgba($value, 0.3);
    }
  }
}

@mixin chains-text-color {
  @each $name, $value in $chain-colors {
    .#{$name} > * {
      color: $value;
    }
  }
}

@mixin chains-text-color-capitalized {
  @each $name, $value in $chain-colors {
    &.#{$name}-color {
      color: $value !important;
      text-transform: capitalize;
    }
  }
}
