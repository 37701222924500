@import 'ui/src/assets/styles/colors';
@import 'ui/src/assets/styles/font-size';
@import 'ui/src/assets/styles/theme';
@import 'ui/src/assets/styles/z-index-hierarchy';
@import 'ui/src/assets/styles/functions/unit-transform';

body.dark-theme {
  .trading-view {
    background-color: #161825;
    border-color: #161825 !important;
  }
}

.trading-view {
  &__container {
    height: 100%;

    &.fullscreen {
      position: fixed;
      margin-top: 0;
      top: 0;
      left: 0;
      z-index: #{$z-index-highest} + 2;
      height: 100%;
      width: 100%;
    }

    iframe {
      height: 100% !important;
    }
  }

  &:not(.resizable) {
    width: 100%;
    height: 100%;

    .trading-view__container {
      width: 100%;
      height: 100%;
    }
  }

  &.resizable {
    height: rem(510px);
    min-height: rem(200px);
    max-height: calc(100vh - 20%);
    position: relative;
    border-radius: 0 0 rem(16px) rem(16px);
    border: 0;
    box-shadow: none;
    padding: 0 0 rem(4px) !important;

    @include use-background(primary, content-background);
  }

  &__drag-handle {
    width: 100%;
    cursor: row-resize;
    background-color: $dark-gray-color;
    align-self: flex-end;
    display: block;
    color: $dark-gray-color;
    font-size: $_font-size-l;
    text-align: center;
    position: absolute;
    height: rem(4px);
    border-bottom-left-radius: rem(16px);
    border-bottom-right-radius: rem(16px);
    visibility: hidden;
    z-index: $z-index-lowest;
    opacity: 0;
    transition:
      visibility 2s,
      z-index 2s;
  }

  &__drag-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
  }

  &__show-button {
    visibility: visible;
    z-index: $z-index-very-low;
    opacity: 1;
    transition:
      visibility 2s,
      z-index 2s,
      opacity 2.5s;
  }

  &__overlay {
    position: absolute;
    color: transparent;
    inset: 0;
    z-index: $z-index-very-low;
  }
}
