/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_list-group.scss
*/
@import '../typo-vars';
@import '../functions/unit-transform';

.list-group-item-action {
  color: #5a6169;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #5a6169;
  background-color: #f7f8f8;
}

.list-group-item-action:active {
  color: #5a6169;
  background-color: #eceeef;
}

.list-group-item {
  padding: rem(12px) rem(20px);
  margin-bottom: rem(-1px);
  background-color: #fff;
  border: rem(1px) solid rgb(0 0 0 / 12.5%);
}

.list-group-item:first-child {
  border-top-left-radius: rem(6px);
  border-top-right-radius: rem(6px);
}

.list-group-item:last-child {
  border-bottom-right-radius: rem(6px);
  border-bottom-left-radius: rem(6px);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
}
