@import 'core';
@import 'ngx-toastr/toastr'; // should be right after 'core' because we must override the Bootstrap's toast styles (which we don't use)
@import 'fonts';
@import 'trading-view';
@import 'app/styles/popover';
@import 'app/styles/tooltip';
@import 'app/styles/coinzilla';
@import 'ui/src/assets/styles/font-size';
@import 'ui/src/assets/styles/colors';
@import 'ui/src/assets/styles/spacing';
@import 'ui/src/assets/styles/theme';
@import 'ui/src/assets/styles/z-index-hierarchy';
@import 'ui/src/assets/styles/functions/unit-transform';
@import 'blockchains/src/assets/styles/mixins/chain-colors-mixins';

/*
IMPORTANT: in this SCSS file and all those imported from this file we cannot use color mixins such as use-text-color or use-background
because the styles from this file are global styles which are not component-encapsulated.
The mixins are meant to be used withing components since they use ':host-context'
TODO: perhaps we should improve the mixins so they can work also in global styles
*/

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html {
  font-family: 'Poppins', sans-serif;

  // Bootstrap takes this font size as base for calculating 'rem' values for ALL elements
  // see node_modules/bootstrap/scss/_variables.scss
  font-size: $_font-size-m;
  font-weight: $_font-weight-regular;
}

body {
  font-weight: $_font-weight-regular;
  height: 100% !important;
  color: $light-text-color;
  background-color: $color-background-white;
  position: relative;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #818ea3 white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: rem(12px);
  height: rem(12px);
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #818ea3;
  border-radius: rem(20px);
  border: rem(3px) solid white;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: _get-color(light, on-primary, text-color);
}

label {
  font-weight: $_font-weight-regular;
}

p {
  @include use-font(xs, regular);

  // IMPORTANT: revert the `line-height` set by the `use-font` mixin so that the <p> texts are not cramped
  line-height: revert;
}

a {
  text-decoration-line: none;

  &:hover {
    color: #0056b3;
  }
}

table {
  white-space: nowrap;
  background-color: transparent;

  tr.disabled {
    td,
    th {
      color: #888;
      opacity: 0.8;
      font-weight: $_font-weight-regular;
    }
  }

  &.table td,
  &.table th {
    color: #142028;

    &.disabled {
      color: #888;
      opacity: 0.8;
      font-weight: $_font-weight-regular;
    }
  }

  &.table td {
    padding: rem(8px) rem(10.4px);
  }
}

.table td,
.table th {
  padding: rem(12px);
}

.table .table {
  background-color: #fff;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.table-sm td,
.table-sm th {
  padding: rem(4.8px);
}

.ngx-datatable.material {
  box-shadow: none;
  height: 100%;
  margin-bottom: 0;
  min-height: rem(600px);
  background-color: transparent;

  .datatable-body-row {
    border-bottom: none !important;
  }

  &:not(.cell-selection) .datatable-body-row:hover {
    background-color: transparent;

    .datatable-row-group {
      background-color: _get-color(light, primary, content-background);
    }

    .datatable-row-left {
      background-color: _get-color(light, primary, content-background) !important;
    }
  }

  .empty-row {
    padding: rem(8px) rem(12px);
    display: flex;
    align-items: center;
    border-top: 0;
    color: _get-color(light, on-primary, text-color);
  }

  .datatable-header {
    height: auto !important;
    background-color: #fbfbfb !important;

    // border-bottom: none !important;
    // box-shadow: 0 0 20px #00000033 !important;

    .datatable-header-cell {
      background-color: #fbfbfb !important;
      color: rgb(0 0 0 / 74%);
      font-size: $_font-size-xs;
      font-weight: $_font-weight-bold;
      line-height: 1.5;
      padding: rem(12px);

      .sort-btn {
        width: rem(10px);
      }
    }
  }

  .datatable-body {
    .progress-linear .container {
      max-width: none;
    }

    .datatable-body-row .datatable-row-group {
      animation-name: fade-in;
      animation-iteration-count: 1;
      animation-duration: 50ms;

      .datatable-body-cell {
        font-size: inherit;
        padding: rem(8px) rem(10.4px);
        border-bottom: rem(1px) solid #e1e5eb;
      }

      @media (min-width: 769px) {
        .datatable-body-cell {
          color: #5a5a5a;
        }
      }
    }
  }

  .datatable-footer {
    .page-count {
      display: none;
    }

    .datatable-footer-inner {
      white-space: nowrap;
    }

    .datatable-pager {
      height: 100%;
    }
  }
}

router-outlet ~ * {
  position: absolute;
  height: 100%;
  width: 100%;
}

.page-header {
  small {
    line-height: rem(12px);
    display: inline-block;
  }

  .page-title {
    text-transform: uppercase;

    img {
      height: rem(20px);
      width: rem(20px);
      border: rem(1px) solid white;
      border-radius: rem(10px);
      background-color: white;
    }
  }

  .page-subtitle {
    color: _get-color(light, on-primary, text-color);
  }
}

.icon-bg-white-rounded {
  width: rem(20px);
  height: rem(20px);
  border: rem(1px) solid white;
  border-radius: rem(10px);
  background-color: white;
}

.list-group,
.card-body {
  transition:
    max-height 0.3s ease-out,
    height 0.3s ease-out;

  .text-rare {
    opacity: 0.6;
  }
}

.loading {
  height: rem(300px);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: rem(100px);
}

.circle {
  position: absolute;
  z-index: $z-index-highest;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  transform: translate(-50%, -50%);
  width: rem(50px);
  height: rem(50px);
  border: rem(2px) solid transparent;
  border-bottom: rem(2px) solid #fff958;
  border-top: rem(2px) solid #b858ff;
  border-radius: 50%;
  animation: animate 3500ms linear infinite;
}

.circle::before {
  content: '';
  position: absolute;
  inset: rem(5px);
  border: rem(2px) solid transparent;
  border-bottom: rem(2px) solid #e74c3c;
  border-top: rem(2px) solid #3ce7d8;
  border-radius: 50%;
  animation: animate 3500ms linear infinite;
}

.circle::after {
  content: '';
  position: absolute;
  inset: rem(12px);
  border: rem(2px) solid transparent;
  border-bottom: rem(2px) solid #39ff8d;
  border-top: rem(2px) solid #ff67ad;
  border-radius: 50%;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.w-300 {
  max-width: rem(300px);
}

small {
  font-size: rem(11px) !important;
}

select,
input,
textarea {
  font-size: $_font-size-xs !important;
  border: none;
}

input {
  &.input-lg {
    border-radius: rem(10px);
    height: rem(34px);
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  &.input-md {
    border-radius: rem(10px);
    height: rem(34px);
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  &:disabled {
    color: _get-color(light, on-primary, text-muted-color);
  }
}

input:not(.buy-color, .sell-color),
textarea:not(.buy-color, .sell-color) {
  color: _get-color(light, on-primary, text-color);

  &:disabled {
    color: _get-color(light, on-primary, text-muted-color);
  }
}

::placeholder {
  color: $dark-gray-color;
  font-size: $_font-size-xs;
  opacity: 1; // Firefox
}

.link-primary {
  color: #0056b3;
  text-decoration: underline;

  &:hover {
    color: rgb(0 86 179 / 55%);
    text-decoration: none;
  }
}

.faded-out {
  opacity: 0.6;
}

//////////////////////////////
//////////////////////////////
// BOXES
.box-home {
  position: relative;

  .box-home-header {
    .button-help {
      top: rem(-15px);
      right: rem(-10px);
      position: absolute;
      text-decoration: none;
      background-color: #0000000a;
      border: rem(1px) solid #3d5170;
      padding: rem(5px) rem(10px);
      border-radius: rem(10px);
      font-weight: $_font-weight-bold;
    }

    img {
      position: absolute;
      left: rem(10px);
      top: rem(-8px);
      border-radius: rem(35px);
      background-color: white;
      width: rem(70px);
      height: rem(70px);
      padding: rem(6px);
      box-shadow: 0 0 rem(8px) #0000004f;
      border: rem(3px) solid #e6e6e6;
    }
  }

  .box-home-body {
    background-color: _get-color(light, primary, content-background);

    &.box-home-body-fit-height {
      min-height: rem(500px);

      @media (max-width: 767.98px) {
        min-height: 0;
        height: auto;
      }

      h6 {
        position: relative;
        border-bottom: rem(1px) solid #212529;
        padding: rem(30px) rem(34px) rem(30px) 0;
        font-size: rem(32px);
        line-height: rem(32px);
        font-weight: $_font-weight-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        fa-icon {
          font-size: $_font-size-l;
        }

        span {
          font-size: $_font-size-m;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          font-size: $_font-size-m;
          padding: rem(9px) 0;
          line-height: rem(15px);

          > .info {
            display: inline-block;
            width: calc(100% - 32px);
          }

          .link {
            vertical-align: middle;
            text-align: right;
            display: inline-block;
            padding-left: rem(10px);

            > a {
              white-space: nowrap;
            }

            fa-icon {
              font-size: $_font-size-l;
            }
          }
        }
      }
    }
  }
}

.new-tag {
  color: #fff !important;
  left: 85%;
  position: absolute;
  top: rem(4px);
}

.buy-color {
  color: $buy-color !important;
}

.sell-color {
  color: $sell-color !important;
}

.warning-color {
  color: $warning-color !important;
}

.remove-color {
  color: $remove-color !important;
}

.add-color {
  color: $add-color !important;
}

.cursor-pointer {
  cursor: pointer;
}

.main-content > .main-content-container.container-fluid {
  min-height: calc(100vh - 120px);
}

.icon-sidebar-nav .main-content {
  padding: 0 0 0 rem(64px); // IMPORTANT: the left padding should be the same as the sidebar's width
}

@media (max-width: 1023px) {
  .icon-sidebar-nav .main-content {
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .page-header .page-title {
    font-size: rem(32px);
  }

  .page-header .page-subtitle {
    font-size: $_font-size-xs;
    font-weight: $_font-weight-regular;
  }
}

.toast-container {
  @media (max-width: 450px) {
    width: calc(100% - 40px) !important;

    &.toast-bottom-right {
      right: rem(20px);
      left: rem(20px);
    }
  }
}

.toast-container .ngx-toastr {
  @include use-text-color(on-primary, text-color);
  @include use-background(primary, sub-content-background);

  font-size: rem(14px);
  font-weight: $_font-weight-regular;
  display: flex;
  justify-content: space-between;
  padding: rem(20px) !important;
  align-items: center;
  box-shadow: none !important;
  border-radius: rem(10px) !important;
  width: rem(372px) !important;

  &:hover {
    box-shadow: none !important;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

app-toast-alert {
  &.toast-success,
  &.toast-error {
    background-image: none;
  }
}

//////////////////////////////
//////////////////////////////

// DARK THEME
body.dark-theme {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: _get-color(dark-theme, on-primary, text-color);
  }

  .page-subtitle {
    color: _get-color(dark-theme, on-primary, text-color);
  }

  scrollbar-color: #020417 $color-dext-contrast-100;

  /* Works on Firefox */
  * {
    scrollbar-color: #020417 $color-dext-contrast-100;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar-track,
  *::-webkit-scrollbar-track {
    background: $dark-contrast-app;
  }

  &::-webkit-scrollbar-corner,
  *::-webkit-scrollbar-corner {
    background-color: $color-dext-contrast-dark;
  }

  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    background-color: $dark-bg-gray-color;
    border: rem(3px) solid $color-dext-contrast-dark;
  }

  background-color: $dark-body-bg-color;
  color: $dark-softgray-color;

  .sk-fading-circle .sk-circle::before {
    background-color: white !important;
  }

  .form-check-label::before {
    background-color: rgb(129 142 163 / 20%);
    border: none;
  }

  // Chrome, Firefox, Opera, Safari 10.1+
  ::placeholder {
    color: $dark-gray-color;
    opacity: 1; // Firefox
  }

  footer {
    background-color: $color-dext-contrast-dark !important;
    border-color: $color-dext-contrast-dark !important;
  }

  .list-group-item {
    border-color: #15192a38 !important;
    background-color: $dark-bg-color;
  }

  table {
    color: $dark-softgray-color;

    &.table td,
    &.table th {
      color: #fff;
    }

    tr.disabled {
      td,
      th {
        color: #888;
        opacity: 0.8;
        font-weight: $_font-weight-regular;
      }
    }

    td {
      border-color: #15192a38 !important;
    }
  }

  thead,
  thead.bg-light {
    color: white;
    background-color: $dark-bg-color !important;
  }

  .btn-group {
    .btn-white {
      color: $color-dext-primary-100;
      background-color: $color-dext-primary-25;
      border-color: $color-dext-primary-25;
    }
  }

  .ngx-datatable.material {
    color: $color-background-white;
    background-color: transparent;

    .empty-row {
      color: _get-color(dark-theme, on-primary, text-color);
    }

    .datatable-header {
      background-color: $dark-bg-color !important;
    }

    .datatable-header .datatable-header-cell {
      color: white;
      background-color: $dark-bg-color !important;
      font-size: $_font-size-xs;
      font-weight: $_font-weight-medium;
      line-height: rem(19px);
    }

    .datatable-body .datatable-body-cell {
      border-bottom: rem(1px) solid #23323c !important;

      &:not(.buy-color, .sell-color) {
        color: #fff !important;
      }
    }

    &:not(.cell-selection) .datatable-body-row:hover {
      .datatable-row-group {
        background-color: _get-color(dark-theme, primary, content-background);
      }

      .datatable-row-left {
        background-color: _get-color(dark-theme, primary, content-background) !important;
      }
    }

    .datatable-footer {
      color: inherit;

      .datatable-footer-inner {
        white-space: nowrap;
      }

      .datatable-pager a {
        color: $dark-softgray-color;
      }
    }

    .toast-container .ngx-toastr {
      @include use-background(primary, gray-background);
    }
  }

  input:not(.buy-color, .sell-color, .form-check-input),
  textarea:not(.buy-color, .sell-color) {
    background: _get-color(dark-theme, primary, sub-content-background);
    color: _get-color(dark-theme, on-primary, text-color);

    &:disabled {
      color: _get-color(dark-theme, on-primary, text-muted-color);
    }
  }

  input:not(.form-check-input),
  textarea {
    background: $dark-bg-over-color;

    &:disabled {
      background: #06060673;
      border-color: #15192a38 !important;
    }
  }

  .box-home {
    .box-home-header {
      img {
        background-color: white !important;
        box-shadow: 0 0 rem(8px) #000000ad !important;
        border: rem(3px) solid #e6e6e6 !important;
      }

      .button-help {
        background: rgb(0 0 0 / 50%) !important;
        border: rem(1px) solid rgb(255 255 255 / 70%) !important;
      }
    }

    .box-home-body {
      background-color: _get-color(dark-theme, primary, content-background);

      .sponsor-principal {
        color: $dark-softgray-color !important;

        img {
          background: none !important;
          border-radius: 0;
          padding: 0;
        }
      }

      h6 {
        border-bottom: rem(1px) solid rgb(255 255 255 / 26.7%);
      }
    }
  }

  .buy-color:not(.sell-strong-color, span, fa-icon[icon='user-secret'], fa-icon[icon='user-friends'], .audit-value) {
    color: #cdffe7 !important;
  }

  .buy-strong-color {
    color: $buy-color !important;
  }

  .sell-color:not(.sell-strong-color, span, fa-icon[icon='user-secret'], fa-icon[icon='user-friends'], .audit-value) {
    color: #ffacb1 !important;
  }

  .sell-strong-color {
    color: $sell-color !important;
  }

  .remove-color {
    color: $remove-color-dark !important;
  }

  .add-color {
    color: $add-color-dark !important;
  }

  .first-column-table {
    background-color: _get-color(dark-theme, primary, body-background);
  }

  .search-mobile-button {
    color: _get-color(dark-theme, on-primary, text-color);

    fa-icon {
      background-color: _get-color(dark-theme, primary, sub-content-background);
    }
  }

  .floating-menu {
    background-color: _get-color(dark-theme, primary, sub-content-background);
    color: _get-color(dark-theme, on-primary, text-color);
  }

  .filter-trade-history-popover {
    background-color: _get-color(dark-theme, primary, sub-content-background);

    .popover-arrow {
      &::after {
        border-top-color: _get-color(dark-theme, primary, sub-content-background);
      }
    }
  }
}

.sell-strong-color {
  color: $sell-color !important;
}

.buy-strong-color {
  color: $buy-color !important;
}

.animate-tx {
  animation: tx-row-animation 200ms linear;
}

@keyframes tx-row-animation {
  0% {
    transform: translateY(100%);
  }

  75% {
    transform: translateY(-30%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@include chains-text-color;

.right-column-table {
  text-align: right !important;
  justify-content: flex-end;
  padding: rem(12px) rem(4.8px) rem(12px) rem(12px) !important;

  &.no-padding {
    padding: 0 rem(12px) !important;
  }
}

.center-column-table {
  text-align: center !important;
  justify-content: center;
}

.first-column-table {
  position: sticky;
  background-color: _get-color(light, primary, body-background);
  z-index: $z-index-high;
  left: 0;
}

.liquidity-warning {
  .datatable-body-cell-label {
    height: 100%;
    width: 100%;
    display: flex;

    * {
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
}

.search-mobile-button {
  color: _get-color(light, on-primary, text-color);
  text-decoration: none;

  fa-icon {
    width: rem(34px);
    height: rem(34px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: _get-color(light, primary, sub-content-background);
    border-radius: rem(50px);
    margin-right: rem(10px);
  }
}

.floating-menu {
  transition:
    opacity 0.275s ease-out 0s,
    height 0s ease 0.5s,
    width 0s ease 0.5s;
  opacity: 0;
  display: none;
  background-color: _get-color(light, primary, sub-content-background);
  color: _get-color(light, on-primary, text-color);
  width: rem(34px);
  height: rem(34px);
  border-radius: rem(30px);
  font-size: rem(17px);

  @media (max-width: 1024px) {
    display: flex;
    height: 0;
    width: 0;

    &.visible,
    &:hover {
      transition: opacity 0.275s ease-out;
      opacity: 1;
      width: rem(34px);
      height: rem(34px);
    }

    .close-fixed-menu {
      display: block;
    }
  }
}

.banner-announcement-content {
  p {
    margin-bottom: 0;
  }
}

.description-container {
  span {
    strong {
      font-weight: $_font-weight-bold;
    }
  }
}

.pair-card-preview {
  .card__body {
    app-trading-view {
      display: none;
    }

    .tv-preview {
      display: block !important;
    }
  }
}

@media (max-width: 1023px) {
  .modal-settings {
    .modal-dialog {
      max-width: 100vw;
      width: 100vw;
    }
  }
}

.filter-trade-history-popover {
  background-color: _get-color(light, primary, sub-content-background);

  .popover-arrow {
    &::after {
      border-top-color: _get-color(light, primary, sub-content-background);
    }
  }
}

@media (max-width: 1023px) {
  body:not(.dark-theme) {
    .positions-form__input {
      @include use-background(primary, content-background);
    }
  }
}
