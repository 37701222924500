/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/utilities/_background.scss
*/

.bg-success {
  background-color: #17c671 !important;
}

.bg-success.card .card-body,
.bg-success.card .card-footer,
.bg-success.card .card-header,
.bg-success.card .card-title {
  background-color: #129857 !important;
}

.bg-success.card .card-footer,
.bg-success.card .card-header {
  background: #15b869;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #129857 !important;
}

.bg-warning.card .card-body,
.bg-warning.card .card-footer,
.bg-warning.card .card-header,
.bg-warning.card .card-title {
  background-color: #cc9000 !important;
}

.bg-warning.card .card-footer,
.bg-warning.card .card-header {
  background: #f0a900;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #cc9000 !important;
}

.bg-light.card .card-body,
.bg-light.card .card-footer,
.bg-light.card .card-header,
.bg-light.card .card-title {
  background-color: #e2e2e2 !important;
}

.bg-light.card .card-footer,
.bg-light.card .card-header {
  background: #f3f3f3;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #e2e2e2 !important;
}

.bg-salmon {
  background-color: #ff4169 !important;
}

.bg-salmon.card .card-body,
.bg-salmon.card .card-footer,
.bg-salmon.card .card-header,
.bg-salmon.card .card-title {
  background-color: #ff0e41 !important;
}

.bg-salmon.card .card-footer,
.bg-salmon.card .card-header {
  background: #ff325d;
}

a.bg-salmon:focus,
a.bg-salmon:hover {
  background-color: #ff0e41 !important;
}

.bg-royal-blue {
  background-color: #674eec !important;
}

.bg-royal-blue.card .card-body,
.bg-royal-blue.card .card-footer,
.bg-royal-blue.card .card-header,
.bg-royal-blue.card .card-title {
  background-color: #3f20e7 !important;
}

.bg-royal-blue.card .card-footer,
.bg-royal-blue.card .card-header {
  background: #5b40eb;
}

a.bg-royal-blue:focus,
a.bg-royal-blue:hover {
  background-color: #3f20e7 !important;
}

.bg-purple {
  background-color: #800080ff;
}

.bg-blue {
  background-color: #00ff;
}

.bg-darkgreen {
  background-color: #006400ff;
}

.bg-darkgoldenrod {
  background-color: #b8860bff;
}

.bg-darkblue {
  background-color: #00008bff;
}

.bg-brown {
  background-color: #a52a2aff;
}

.bg-saddlebrown {
  background-color: #8b4513ff;
}

.bg-palegreen {
  background-color: #98fb98ff;
}

.bg-squared {
  border-radius: 0;
}

.bg-primary {
  color: #fff;
  background-color: #00b8d8;
}

.bg-primary[href]:focus,
.bg-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.bg-secondary[href]:focus,
.bg-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #42484e;
}

.bg-success[href]:focus,
.bg-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #129857;
}

.bg-outline-success {
  background: 0 0;
  border: rem(1px) solid #17c671;
  color: #17c671;
}

.bg-info {
  color: #fff;
  background-color: #00b8d8 !important;
}

.bg-info[href]:focus,
.bg-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #008da5;
}

.bg-warning {
  color: #212529;
  background-color: #ffb400;
}

.bg-warning[href]:focus,
.bg-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #cc9000;
}

.bg-danger[href]:focus,
.bg-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #97122e;
}

.bg-outline-danger {
  background: 0 0;
  border: rem(1px) solid #c4183c;
  color: #c4183c;
}

.bg-light {
  color: #212529;
  background-color: #fbfbfb;
}

.bg-light[href]:focus,
.bg-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #e2e2e2;
}

.bg-dark {
  color: #fff;
  background-color: #212529;
}

.bg-dark[href]:focus,
.bg-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0a0c0d;
}
