/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_forms.scss
*/
@import '../font-size';
@import '../theme';
@import '../functions/unit-transform';

.form-control {
  height: auto;
  padding: rem(7px) rem(12px);
  font-size: $_font-size-xs;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  will-change: border-color, box-shadow;
  border-radius: rem(4px);
  box-shadow: none;
  transition:
    box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

  &::placeholder {
    color: #838ea1;
    font-size: $_font-size-s;
  }
}

.form-control:hover {
  border-color: #b3bdcc;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00b8d8;
  box-shadow: none;
}

.form-control:focus:hover {
  border-color: #00b8d8;
}

.form-control::placeholder {
  color: #868e96;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f6f7;
}

.form-control:disabled:hover,
.form-control[readonly]:hover {
  border-color: #e1e5eb;
  cursor: not-allowed;
}

.form-control[readonly]:not(:disabled):focus {
  box-shadow: none;
  border-color: #e1e5eb;
}

.col-form-label {
  font-size: $_font-size-xs;
  color: _get-color(light, on-primary, text-color);
  padding-top: rem(8px);
  padding-bottom: rem(8px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: rem(14px);
  padding-bottom: rem(14px);
  font-size: $_font-size-s;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: rem(7.858px);
  padding-bottom: rem(7.858px);
  font-size: rem(11px);
  line-height: 1.5;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

select.form-control:hover {
  cursor: pointer;
}

.form-text {
  font-size: $_font-size-xxs;
  color: _get-color(light, on-primary, soft-gray-color);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: $buy-color;
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #c4183c;
}

// DARK THEME
body.dark-theme {
  .col-form-label {
    color: _get-color(dark-theme, on-primary, text-color);
  }

  .form-text {
    color: _get-color(dark-theme, on-primary, soft-gray-color);
  }

  .form-control::placeholder {
    color: #838ea1;
  }
}
