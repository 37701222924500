/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_modal.scss
*/
@import '../theme';
@import '../typo-vars';
@import '../z-index-hierarchy';
@import '../functions/unit-transform';

.modal {
  z-index: #{$z-index-highest} + 4;
}

.modal-dialog {
  margin: rem(10px);

  &__news {
    max-width: rem(700px) !important;

    .modal-content {
      border: rem(1px) solid $dark-gray-color;
    }
  }
}

.modal.fade .modal-dialog {
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.modal-dialog-centered {
  min-height: calc(100% - (rem(10px) * 2));
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
}

.modal-content {
  background-color: _get-color(light, primary, content-background);
  border: none;
  border-radius: rem(15px);
  animation-name: example;
  animation-duration: 0.3s;
  font-size: $_font-size-xs;
}

.modal-backdrop {
  z-index: calc($z-index-highest + 899995) !important;
  background-color: #5a6169;
}

.modal-backdrop.show {
  opacity: 1 !important;
  background-color: rgb(0 0 0 / 70%);

  &.fade {
    opacity: 1 !important;
  }
}

.modal-header {
  padding: rem(15px) rem(15px) rem(15px) rem(35px);
  border-bottom: none;
  align-items: center;

  .close {
    display: flex;
    margin-left: auto;
  }
}

.modal-title {
  line-height: 1.5;
}

.modal-body {
  padding: rem(30px) rem(35px);
}

.modal-footer {
  padding: 0 rem(35px) rem(15px);
  border-top: none;
}

@media (min-width: 576px) {
  .modal-dialog:not(.modal-xl) {
    max-width: rem(500px);
  }

  .modal-dialog {
    margin: rem(30px) auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (rem(30px) * 2));
  }

  .modal-sm {
    max-width: rem(300px);
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: rem(800px);
  }
}

@media (max-width: 1200px) {
  .modal-xl {
    max-width: rem(900px);
  }
}

.wide-modal .modal-content {
  @media (min-width: 1100px) {
    width: rem(1100px);
  }
}

.full-screen-modal {
  @media (max-width: 1023px) {
    .modal-content {
      background-color: _get-color(light, primary, body-background);
      position: fixed;
      height: 100%;
      width: 100%;
    }
  }
}

// DARK THEME
body.dark-theme {
  .modal-content {
    background-color: _get-color(dark-theme, primary, content-background);
  }

  .full-screen-modal {
    @media (max-width: 1023px) {
      .modal-content {
        background-color: _get-color(dark-theme, primary, body-background);
      }
    }
  }
}
