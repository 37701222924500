@use 'sass:map';
/* stylelint-disable scss/load-no-partial-leading-underscore */
@import '../themes/_dark';
@import '../themes/_light';
/* stylelint-enable scss/load-no-partial-leading-underscore */

$_themes: (
  'dark-theme': $_dark,
  'light': $_light,
);

@function _get-color($theme-name, $color, $type: null) {
  $theme: map.get($_themes, $theme-name);

  @if map-has-key($theme, $color) {
    $name: map.get($theme, $color);

    @if $type {
      @if map-has-key($name, $type) {
        @return map-get($name, $type);
      } @else {
        @error 'El typo de color no está disponible';
      }
    }

    @return $name;
  } @else {
    @error 'El color no está disponible';
  }
}

@function _get-important($important) {
  @return #{if($important, '!important', '')};
}
