/* TYPOGRAPHY */

/* Font family */
$_font-family-primary: 'Poppins';
$_font-family-secondary: 'Roboto';
$_font-family-fallback: sans-serif;
$_font-family: $_font-family-primary, $_font-family-secondary, $_font-family-fallback;

/* Font Style */
$_font-style: normal;

/* Font Weight */
$_font-weight-bold: 700;
$_font-weight-semibold: 600;
$_font-weight-medium: 500;
$_font-weight-regular: 400;
$_font-weight-light: 300;

/* Font Size BIG */
$_font-size-ultra: 42px;
$_font-size-mega: 38px;
$_font-size-big: 34px;

/* Font Size */
$_font-size-xxl: 28px;
$_font-size-xl: 24px;
$_font-size-l: 20px;
$_font-size-m: 16px;
$_font-size-s: 14px;
$_font-size-xs: 13px;
$_font-size-xxs: 12px;

/* Line Height */
$_line-height-mega: 48px;
$_line-height-xxl: 36px;
$_line-height-xl: 32px;
$_line-height-l: 26px;
$_line-height-m: 22px;
$_line-height-s: 20px;
$_line-height-xs: 16px;
$_line-height-xxs: 12px;

/* Property Vars */

/* Font Weight Types */
$_font-weight-types: (
  bold: $_font-weight-bold,
  medium: $_font-weight-medium,
  regular: $_font-weight-regular,
  light: $_font-weight-light,
);

/* Font Size Types */
$_font-size-types: (
  mega: $_font-size-mega,
  xxl: $_font-size-xxl,
  xl: $_font-size-xl,
  l: $_font-size-l,
  m: $_font-size-m,
  s: $_font-size-s,
  xs: $_font-size-xs,
  xxs: $_font-size-xxs,
);

/* Line Height Types */
$_line-height-types: (
  mega: $_line-height-mega,
  xxl: $_line-height-xxl,
  xl: $_line-height-xl,
  l: $_line-height-l,
  m: $_line-height-m,
  s: $_line-height-s,
  xs: $_line-height-xs,
  xxs: $_line-height-xxs,
);
