$chain-colors: (
  ether: #6c89ff,
  bnb: #efb90a,
  polygon: #8247e5,
  fantom: #1969ff,
  cronos: #1199fa,
  avalanche: #e74042,
  oasis: #0092f6,
  velas: #0037c1,
  metis: #2adacc,
  kucoin: #31d7a0,
  optimism: #ff0420,
  arbitrum: #28a0f0,
  celo: #35d07f,
  telos: #cbcaf9,
  aurora: #6ee4e5,
  moonbeam: #ea5aa2,
  moonriver: #f2a007,
  harmony: #3ad8d1,
  astar: #1b6dc1,
  fuse: #b3ffbb,
  iotex: #44ffb2,
  oktc: #205fec,
  heco: #aefb19,
  milkomeda: #f07d00,
  dfk: #e74042,
  solana: #14f195,
  evmos: #fff3cd,
  dogechain: #ba9f33,
  canto: #66ff9a,
  etc: #14b981,
  gnosis: #0ae33a,
  bitgert: #b3fa08,
  arbitrumnova: #ef8220,
  songbird: #e2665c,
  redlight: #ff3c3b,
  kardia: #d77ac7,
  smartbch: #3fdb88,
  conflux: #4fc0d0,
  tomb: #9585fe,
  boba: #cbff00,
  elastos: #a97ae7,
  shiden: #9b2abc,
  fusion: #017bfe,
  wan: #2daef1,
  rsk: #03826d,
  nova: #b24141,
  hoo: #00d1ae,
  cube: #43ffff,
  syscoin: #0680fe,
  ronin: #c4e3fb,
  viction: #3fe8b4,
  kava: #f9443e,
  thundercore: #35d1d1,
  meter: #1787d0,
  echelon: #ed30d1,
  shib: #e20dcd,
  ethw: #6c85dd,
  kek: #3e8065,
  dis: #6481f8,
  sx: #3ec6e9,
  muu: #007bff,
  alvey: #487cb6,
  aptos: #2ed8a7,
  multiversx: #23f7dd,
  flare: #cf2e2e,
  pom: #1e1b57,
  ultron: #150b42,
  energi: #00e676,
  exosama: #171923,
  coredao: #ff9635,
  ethergoerli: #afeeee,
  filecoin: #0090ff,
  zksync: #712f93,
  polygonzkevm: #8141f2,
  pulse: #9a01f8,
  klaytn: #ff2f00,
  linea: #61dfff,
  base: #0052ff,
  mantle: #1a443a,
  bitrock: #28a745,
  opbnb: #efb90a,
  shibarium: #6fbb3f,
  starknet: #0c0c4f,
  scroll: #87e3d4,
  manta: #8a76f3,
  kujira: #e53935,
  blast: #fcf953,
  bittorrent: #6055e9,
  osmosis: #ca2ebd,
  xlayer: #111,
  shimmer: #00e0ca,
  mode: #d8ff00,
  ton: #4396e3,
  hedera: #222,
  near: #0d494a,
  tron: #780000,
  bitlayer: #e46f1b,
  zklinknova: #03d498,
  apechain: #004bc4,
  elysium: #0d6efd,
  zkfair: #e13022,
  cronoszkevm: #33546b,
  iotaevm: #6b7277,
  worldchain: #fff,
  chiliz: #ff2600,
  icp: #9b5cfa,
);
