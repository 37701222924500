@import './size-vars';

/* MARGINS */

/* All sides */
.m {
  margin: $spacing-size-default;
}

.m0 {
  margin: $spacing-size-none;
}

.m025 {
  margin: $spacing-size-025;
}

.m05 {
  margin: $spacing-size-05;
}

.m075 {
  margin: $spacing-size-075;
}

.m1 {
  margin: $spacing-size-1;
}

.m15 {
  margin: $spacing-size-15;
}

.m2 {
  margin: $spacing-size-2;
}

.m25 {
  margin: $spacing-size-25;
}

.m3 {
  margin: $spacing-size-3;
}

.m35 {
  margin: $spacing-size-35;
}

.m4 {
  margin: $spacing-size-4;
}

.mx {
  @extend %ml;
  @extend %mr;
}

.mx0 {
  @extend %ml0;
  @extend %mr0;
}

.mx025 {
  @extend %ml025;
  @extend %mr025;
}

.mx05 {
  @extend %ml05;
  @extend %mr05;
}

.mr05 {
  @extend %mr05;
}

.mx075 {
  @extend %ml075;
  @extend %mr075;
}

.mx1 {
  @extend %ml1;
  @extend %mr1;
}

.mx15 {
  @extend %ml15;
  @extend %mr15;
}

.mx2 {
  @extend %ml2;
  @extend %mr2;
}

.mx25 {
  @extend %ml25;
  @extend %mr25;
}

.mx3 {
  @extend %ml3;
  @extend %mr3;
}

.mx35 {
  @extend %ml35;
  @extend %mr35;
}

.mx4 {
  @extend %ml4;
  @extend %mr4;
}

.my {
  @extend %mt;
  @extend %mb;
}

.my0 {
  @extend %mt0;
  @extend %mb0;
}

.my025 {
  @extend %mt025;
  @extend %mb025;
}

.my05 {
  @extend %mt05;
  @extend %mb05;
}

.my075 {
  @extend %mt075;
  @extend %mb075;
}

.my1 {
  @extend %mt1;
  @extend %mb1;
}

.my15 {
  @extend %mt15;
  @extend %mb15;
}

.my2 {
  @extend %mt2;
  @extend %mb2;
}

.my25 {
  @extend %mt25;
  @extend %mb25;
}

.my3 {
  @extend %mt3;
  @extend %mb3;
}

.my35 {
  @extend %mt35;
  @extend %mb35;
}

.my4 {
  @extend %mt4;
  @extend %mb4;
}

/* Top side */
%mt {
  margin-top: $spacing-size-default;
}

%mt0 {
  margin-top: $spacing-size-none;
}

%mt025 {
  margin-top: $spacing-size-025;
}

%mt05 {
  margin-top: $spacing-size-05;
}

%mt075 {
  margin-top: $spacing-size-075;
}

%mt1 {
  margin-top: $spacing-size-1;
}

%mt15 {
  margin-top: $spacing-size-15;
}

%mt2 {
  margin-top: $spacing-size-2;
}

%mt25 {
  margin-top: $spacing-size-25;
}

%mt3 {
  margin-top: $spacing-size-3;
}

%mt35 {
  margin-top: $spacing-size-35;
}

%mt4 {
  margin-top: $spacing-size-4;
}

/* Right side */
%mr {
  margin-right: $spacing-size-default;
}

%mr0 {
  margin-right: $spacing-size-none;
}

%mr025 {
  margin-right: $spacing-size-025;
}

%mr05 {
  margin-right: $spacing-size-05;
}

%mr075 {
  margin-right: $spacing-size-075;
}

%mr1 {
  margin-right: $spacing-size-1;
}

%mr15 {
  margin-right: $spacing-size-15;
}

%mr2 {
  margin-right: $spacing-size-2;
}

%mr25 {
  margin-right: $spacing-size-25;
}

%mr3 {
  margin-right: $spacing-size-3;
}

%mr35 {
  margin-right: $spacing-size-35;
}

%mr4 {
  margin-right: $spacing-size-4;
}

/* Bottom side */
%mb {
  margin-bottom: $spacing-size-default;
}

%mb0 {
  margin-bottom: $spacing-size-none;
}

%mb025 {
  margin-bottom: $spacing-size-025;
}

%mb05 {
  margin-bottom: $spacing-size-05;
}

%mb075 {
  margin-bottom: $spacing-size-075;
}

%mb1 {
  margin-bottom: $spacing-size-1;
}

%mb15 {
  margin-bottom: $spacing-size-15;
}

%mb2 {
  margin-bottom: $spacing-size-2;
}

%mb25 {
  margin-bottom: $spacing-size-25;
}

%mb3 {
  margin-bottom: $spacing-size-3;
}

%mb35 {
  margin-bottom: $spacing-size-35;
}

%mb4 {
  margin-bottom: $spacing-size-4;
}

/* Left side */
%ml {
  margin-left: $spacing-size-default;
}

%ml0 {
  margin-left: $spacing-size-none;
}

%ml025 {
  margin-left: $spacing-size-025;
}

%ml05 {
  margin-left: $spacing-size-05;
}

%ml075 {
  margin-left: $spacing-size-075;
}

%ml1 {
  margin-left: $spacing-size-1;
}

%ml15 {
  margin-left: $spacing-size-15;
}

%ml2 {
  margin-left: $spacing-size-2;
}

%ml25 {
  margin-left: $spacing-size-25;
}

%ml3 {
  margin-left: $spacing-size-3;
}

%ml35 {
  margin-left: $spacing-size-35;
}

%ml4 {
  margin-left: $spacing-size-4;
}

/* PADDINGS */

/* All sides */
%p {
  padding: $spacing-size-default;
}

%p0 {
  padding: $spacing-size-none;
}

%p025 {
  padding: $spacing-size-025;
}

%p05 {
  padding: $spacing-size-05;
}

%p075 {
  padding: $spacing-size-075;
}

%p1 {
  padding: $spacing-size-1;
}

%p15 {
  padding: $spacing-size-15;
}

%p2 {
  padding: $spacing-size-2;
}

%p25 {
  padding: $spacing-size-25;
}

%p3 {
  padding: $spacing-size-3;
}

%p35 {
  padding: $spacing-size-35;
}

%p4 {
  padding: $spacing-size-4;
}

.px {
  @extend %pl;
  @extend %pr;
}

.px0 {
  @extend %pl0;
  @extend %pr0;
}

.p0 {
  @extend %p0;
}

.px025 {
  @extend %pl025;
  @extend %pr025;
}

.px05 {
  @extend %pl05;
  @extend %pr05;
}

.px075 {
  @extend %pl075;
  @extend %pr075;
}

.px1 {
  @extend %pl1;
  @extend %pr1;
}

.px15 {
  @extend %pl15;
  @extend %pr15;
}

.px2 {
  @extend %pl2;
  @extend %pr2;
}

.px25 {
  @extend %pl25;
  @extend %pr25;
}

.px3 {
  @extend %pl3;
  @extend %pr3;
}

.px35 {
  @extend %pl35;
  @extend %pr35;
}

.px4 {
  @extend %pl4;
  @extend %pr4;
}

.py {
  @extend %pt;
  @extend %pb;
}

.py0 {
  @extend %pt0;
  @extend %pb0;
}

.pt0 {
  @extend %pt0;
}

.py025 {
  @extend %pt025;
  @extend %pb025;
}

.py05 {
  @extend %pt05;
  @extend %pb05;
}

.py075 {
  @extend %pt075;
  @extend %pb075;
}

.py1 {
  @extend %pt1;
  @extend %pb1;
}

.py15 {
  @extend %pt15;
  @extend %pb15;
}

.py2 {
  @extend %pt2;
  @extend %pb2;
}

.py25 {
  @extend %pt25;
  @extend %pb25;
}

.py3 {
  @extend %pt3;
  @extend %pb3;
}

.py35 {
  @extend %pt35;
  @extend %pb35;
}

.py4 {
  @extend %pt4;
  @extend %pb4;
}

/* Top side */
%pt {
  padding-top: $spacing-size-default;
}

%pt0 {
  padding-top: $spacing-size-none;
}

%pt025 {
  padding-top: $spacing-size-025;
}

%pt05 {
  padding-top: $spacing-size-05;
}

%pt075 {
  padding-top: $spacing-size-075;
}

%pt1 {
  padding-top: $spacing-size-1;
}

%pt15 {
  padding-top: $spacing-size-15;
}

%pt2 {
  padding-top: $spacing-size-2;
}

%pt25 {
  padding-top: $spacing-size-25;
}

%pt3 {
  padding-top: $spacing-size-3;
}

%pt35 {
  padding-top: $spacing-size-35;
}

%pt4 {
  padding-top: $spacing-size-4;
}

/* Right side */
%pr {
  padding-right: $spacing-size-default;
}

%pr0 {
  padding-right: $spacing-size-none;
}

%pr025 {
  padding-right: $spacing-size-025;
}

%pr05 {
  padding-right: $spacing-size-05;
}

%pr075 {
  padding-right: $spacing-size-075;
}

%pr1 {
  padding-right: $spacing-size-1;
}

%pr15 {
  padding-right: $spacing-size-15;
}

%pr2 {
  padding-right: $spacing-size-2;
}

%pr25 {
  padding-right: $spacing-size-25;
}

%pr3 {
  padding-right: $spacing-size-3;
}

%pr35 {
  padding-right: $spacing-size-35;
}

%pr4 {
  padding-right: $spacing-size-4;
}

/* Bottom side */
%pb {
  padding-bottom: $spacing-size-default;
}

%pb0 {
  padding-bottom: $spacing-size-none;
}

%pb025 {
  padding-bottom: $spacing-size-025;
}

%pb05 {
  padding-bottom: $spacing-size-05;
}

%pb075 {
  padding-bottom: $spacing-size-075;
}

%pb1 {
  padding-bottom: $spacing-size-1;
}

%pb15 {
  padding-bottom: $spacing-size-15;
}

%pb2 {
  padding-bottom: $spacing-size-2;
}

%pb25 {
  padding-bottom: $spacing-size-25;
}

%pb3 {
  padding-bottom: $spacing-size-3;
}

%pb35 {
  padding-bottom: $spacing-size-35;
}

%pb4 {
  padding-bottom: $spacing-size-4;
}

/* Left side */
%pl {
  padding-left: $spacing-size-default;
}

%pl0 {
  padding-left: $spacing-size-none;
}

%pl025 {
  padding-left: $spacing-size-025;
}

%pl05 {
  padding-left: $spacing-size-05;
}

%pl075 {
  padding-left: $spacing-size-075;
}

%pl1 {
  padding-left: $spacing-size-1;
}

%pl15 {
  padding-left: $spacing-size-15;
}

%pl2 {
  padding-left: $spacing-size-2;
}

%pl25 {
  padding-left: $spacing-size-25;
}

%pl3 {
  padding-left: $spacing-size-3;
}

%pl35 {
  padding-left: $spacing-size-35;
}

%pl4 {
  padding-left: $spacing-size-4;
}
