/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_card.scss
*/
@import '../typo-vars';
@import '../theme';
@import '../functions/unit-transform';

.card {
  background-color: _get-color(light, primary, content-background);
  border: none;
  border-radius: rem(10px);
  box-shadow: none;
  color: $light-text-color;
}

.card-header {
  position: relative;
  background-color: _get-color(light, primary, content-background);
  padding: rem(22px) rem(27px) 0;
  border-bottom: none;

  &:first-child {
    border-radius: rem(15px) rem(15px) 0 0;
  }
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: rem(10px);
  border-top-right-radius: rem(10px);
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: rem(10px);
  border-bottom-left-radius: rem(10px);
}

.card .list-group-item {
  padding: rem(13px) rem(30px);
}

.card .card-text {
  margin-bottom: rem(25px);
}

.card a:hover {
  text-decoration: none;
}

.card-small .card-body,
.card-small .card-footer,
.card-small .card-header {
  padding: rem(16px);
}

.card-body {
  padding: rem(30px);
  border-radius: 0 0 rem(15px) rem(15px);
}

.card-body > p:last-child {
  margin-bottom: 0;
}

.card-title {
  font-weight: $_font-weight-medium;
  margin-bottom: rem(12px);
}

.card-subtitle {
  margin-top: rem(-17.5px);
}

.card-footer {
  background-color: _get-color(light, primary, content-background);
  padding: rem(17.5px) rem(30px);
  border-top: none;
}

.card-footer:last-child {
  border-radius: 0 0 rem(10px) rem(10px);
}

.card-header-tabs {
  margin-bottom: rem(-16px);
  border-bottom: 0;
}

.card-header-tabs .nav-link,
.card-header-tabs .nav-link:hover {
  border-bottom: transparent;
}

.card-img-overlay {
  padding: rem(30px) rem(35px);
  background: rgb(90 97 105 / 50%);
  border-radius: rem(10px);
}

.card-img-overlay .card-title {
  color: #fff;
}

.card-img {
  border-radius: rem(10px);
}

.card-img-top {
  border-top-left-radius: rem(10px);
  border-top-right-radius: rem(10px);
}

.card-img-bottom {
  border-bottom-right-radius: rem(10px);
  border-bottom-left-radius: rem(10px);
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  font-weight: $_font-weight-medium;
}

// DARK THEME
body.dark-theme {
  .card {
    background-color: _get-color(dark-theme, primary, content-background);
    color: #fff;
  }

  .card-header {
    background-color: _get-color(dark-theme, primary, content-background);
  }

  .card-footer {
    background-color: _get-color(dark-theme, primary, content-background);
  }
}
