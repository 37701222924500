@import '../colors';
@import '../z-index-hierarchy';
@import '../functions/unit-transform';

.app-loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  inset: 0;

  .app-dxt-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: rem(16px) 0;
    position: absolute;
    z-index: $z-index-normal;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .app-dot-pulse {
    position: relative;
    left: rem(-9999px);
    width: rem(8px);
    height: rem(8px);
    border-radius: rem(50px);
  }

  .app-dot-pulse::before,
  .app-dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: rem(8px);
    height: rem(8px);
    border-radius: rem(5px);
  }

  &.light {
    background-color: $color-background-white;

    .app-dot-pulse::before,
    .app-dot-pulse::after {
      background-color: $dark-body-bg-color;
      color: $dark-body-bg-color;
    }

    .app-dot-pulse {
      background-color: $dark-body-bg-color;
      color: $dark-body-bg-color;
      box-shadow: rem(9999px) 0 0 rem(-5px) $dark-body-bg-color;
      animation: dotpulse 1.5s infinite linear;
      animation-delay: 0.25s;

      &::before {
        box-shadow: rem(9984px) 0 0 rem(-5px) $dark-body-bg-color;
        animation: dotpulsebefore 1.5s infinite linear;
        animation-delay: 0s;
      }

      &::after {
        box-shadow: rem(10014px) 0 0 rem(-5px) $dark-body-bg-color;
        animation: dotpulseafter 1.5s infinite linear;
        animation-delay: 0.5s;
      }
    }
  }

  &.dark {
    background-color: $dark-body-bg-color;

    .app-dot-pulse::before,
    .app-dot-pulse::after {
      background-color: $color-background-white;
      color: $color-background-white;
    }

    .app-dot-pulse {
      background-color: $color-background-white;
      color: $color-background-white;
      box-shadow: rem(9999px) 0 0 rem(-5px) $color-background-white;
      animation: dotpulsedark 1.5s infinite linear;
      animation-delay: 0.25s;

      &::before {
        box-shadow: rem(9984px) 0 0 rem(-5px) $color-background-white;
        animation: dotpulsebeforedark 1.5s infinite linear;
        animation-delay: 0s;
      }

      &::after {
        box-shadow: rem(10014px) 0 0 rem(-5px) $color-background-white;
        animation: dotpulseafterdark 1.5s infinite linear;
        animation-delay: 0.5s;
      }
    }
  }
}

@keyframes dotpulsebefore {
  0% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #0000006b;
  }

  30% {
    box-shadow: rem(9984px) 0 0 rem(2px) #0000006b;
  }

  60%,
  100% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #0000006b;
  }
}

@keyframes dotpulse {
  0% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #000;
  }

  30% {
    box-shadow: rem(9999px) 0 0 rem(2px) #000;
  }

  60%,
  100% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #000;
  }
}

@keyframes dotpulseafter {
  0% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #0000006b;
  }

  30% {
    box-shadow: rem(10014px) 0 0 rem(2px) #0000006b;
  }

  60%,
  100% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #0000006b;
  }
}

@keyframes dotpulsebeforedark {
  0% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(9984px) 0 0 rem(2px) #ffffff6b;
  }

  60%,
  100% {
    box-shadow: rem(9984px) 0 0 rem(-5px) #ffffff6b;
  }
}

@keyframes dotpulsedark {
  0% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(9999px) 0 0 rem(2px) #fff;
  }

  60%,
  100% {
    box-shadow: rem(9999px) 0 0 rem(-5px) #fff;
  }
}

@keyframes dotpulseafterdark {
  0% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #ffffff6b;
  }

  30% {
    box-shadow: rem(10014px) 0 0 rem(2px) #ffffff6b;
  }

  60%,
  100% {
    box-shadow: rem(10014px) 0 0 rem(-5px) #ffffff6b;
  }
}
