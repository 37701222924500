/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_alert.scss
*/

@import '../font-size';
@import '../functions/unit-transform';

.alert {
  padding: rem(12px) rem(20px);
  margin-bottom: rem(16px);
  border: none;
  border-radius: 0;
  font-size: $_font-size-xs;

  .close-alert {
    font-size: rem(19px);
  }
}

.alert-link {
  font-weight: $_font-weight-medium;
}

.alert-primary {
  color: #f5faff;
  background-color: #00b8d8;
}

.alert-primary .alert-link {
  color: #f5faff;
}

.alert-secondary {
  color: #d9dcdf;
  background-color: #5a6169;
}

.alert-secondary .alert-link {
  color: #d9dcdf;
}

.alert-success {
  color: #d7fae9;
  background-color: #17c671;
}

.alert-success .alert-link {
  color: #d7fae9;
}

.alert-info {
  color: #cef8ff;
  background-color: #00b8d8;
}

.alert-info .alert-link {
  color: #cef8ff;
}

.alert-warning {
  color: #fffcf5;
  background-color: #ffb400;
}

.alert-warning .alert-link {
  color: #fffcf5;
}

.alert-danger {
  color: #fad7de;
  background-color: #c4183c;
}

.alert-danger .alert-link {
  color: #fad7de;
}

.alert-dismissible .close {
  top: 0;
  right: 0;
  padding: rem(8px) rem(20px);
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.alert-dismissible .close:hover {
  cursor: pointer;
}
