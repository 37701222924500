/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_custom-forms.scss
*/
@import '../theme';
@import '../typo-vars';
@import '../colors';
@import '../functions/unit-transform';

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: transparent;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none;
}

.custom-select.is-valid:hover,
.form-control.is-valid:hover,
.was-validated .custom-select:valid:hover,
.was-validated .form-control:valid:hover {
  border-color: transparent;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: $success-color;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #57eca4;
  border-color: #2ae68b;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #2ae68b;
}

.custom-toggle .custom-control-input:not(:checked).is-valid ~ .custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:not(:checked):valid ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-toggle .custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:valid ~ .custom-control-label::before {
  background-color: $success-color;
}

.custom-toggle .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-toggle .custom-control-input:invalid ~ .custom-control-label::after {
  background-color: #eb8c95;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #c4183c;
}

.custom-select.is-invalid:hover,
.form-control.is-invalid:hover,
.was-validated .custom-select:invalid:hover,
.was-validated .form-control:invalid:hover {
  border-color: #c4183c;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #c4183c;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #ea5876;
  border-color: #e52a51;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e52a51;
}

.custom-toggle .custom-control-input:not(:checked).is-invalid ~ .custom-control-label::before,
.was-validated .custom-toggle .custom-control-input:not(:checked):invalid ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control {
  line-height: rem(24px);
  min-height: rem(24px);
  padding-left: rem(27px);
}

.custom-control:hover {
  cursor: pointer;
}

.custom-control .custom-control-label::before {
  pointer-events: all;
}

.custom-control-inline {
  margin-right: rem(16px);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: $color-dext-primary-100;
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}

.custom-control-input:disabled ~ .custom-control-label:hover {
  cursor: not-allowed;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: static;
}

.custom-control-label:hover {
  cursor: pointer;
}

.custom-control-label::before {
  top: rem(5.853px);
  left: rem(3.2px);
  width: rem(13.6px);
  height: rem(13.6px);
  background-color: #fff;
  border: rem(1px) solid #e1e5eb;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  box-shadow: none;
}

.custom-control-label::after {
  top: rem(8.32px);
  left: rem(6.4px);
  width: rem(7.6px);
  height: rem(7.6px);
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: rem(2px);
}

.custom-checkbox .custom-control-label::after {
  content: '';
  position: absolute;
  top: rem(2px);
  left: rem(8.8px);
  width: rem(4px);
  height: rem(10px);
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: rem(2px) solid #fff;
  border-bottom: rem(2px) solid #fff;
  transition:
    transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    -webkit-transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 0.1s;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: none;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
  background-image: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border: none;
  background-color: $color-dext-primary-100;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  content: '';
  position: absolute;
  transform: scale(1);
  background-image: none;
  background-color: #fff;
  border: none;
  width: rem(10px);
  height: rem(2px);
  top: rem(11px);
  left: rem(4px);
  opacity: 1;
  transition: none;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
  border-color: #becad6;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-label::after {
  content: '';
  border-radius: 50%;
  transform: scale(0);
  background-image: none !important;
  position: absolute;
  background-color: #fff;
  width: rem(8px);
  height: rem(8px);
  top: rem(8px);
  left: rem(5px);
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 0.1s;
  opacity: 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $color-dext-primary-100;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  transform: scale(1);
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background: #becad6;
}

.custom-select {
  height: rem(35.5px);
  padding: rem(6px) rem(28px) rem(6px) rem(12px);
  line-height: 1.5;
  color: #495057;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 12px center;
  background-size: rem(8px) rem(10px);
  border: none;
  font-weight: $_font-weight-regular;
  font-size: $_font-size-xs;
  transition:
    box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border-radius: rem(4px);
}

.custom-select,
.custom-select option {
  color: _get-color(light, on-primary, text-color);
  background-color: _get-color(light, primary, sub-content-background);
  border: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select:hover:not(:focus, :disabled) {
  cursor: pointer;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  padding-right: rem(12px);
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select-sm {
  height: rem(32.215px);
  padding-top: rem(6px);
  padding-bottom: rem(6px);
  font-size: rem(11px);
  border-radius: rem(4px);
}

.custom-select-lg {
  height: rem(49px);
  font-size: $_font-size-l;
  padding-top: rem(6px);
  padding-bottom: rem(6px);
  border-radius: rem(4px);
}

.custom-toggle {
  position: relative;
  padding-left: rem(60px);
}

.custom-toggle .custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: rem(38px);
  height: rem(21px);
  background: #fff;
  border-radius: rem(100px);
  border: rem(1px) solid #e1e5eb;
}

.custom-toggle .custom-control-label::after {
  content: '';
  position: absolute;
  top: rem(2px);
  left: rem(2px);
  width: rem(17px);
  height: rem(17px);
  background: #e1e5eb;
  border-radius: rem(100px);
  transition: 350ms;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label::before {
  background: $color-dext-primary-100;
  border-color: $color-dext-primary-100;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label::after {
  left: rem(36px);
  transform: translateX(-100%);
  background: #fff;
}

.custom-toggle .custom-control-input:checked:disabled ~ .custom-control-label::before {
  background: #e9ecef;
  border-color: #e1e5eb;
}

.custom-toggle .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background: #becad6;
}

.custom-toggle .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  width: rem(26px);
}

.custom-toggle .custom-control-input:active:not(:checked) ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-toggle .custom-control-input:disabled:active ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-toggle.custom-toggle-sm {
  padding-left: rem(42px);
}

.custom-toggle.custom-toggle-sm .custom-control-label::before {
  top: rem(3px);
  position: absolute;
  display: block;
  width: rem(35px);
  height: rem(18px);
  background: #fff;
  border-radius: rem(100px);
  border: rem(1px) solid #e1e5eb;
}

.custom-toggle.custom-toggle-sm .custom-control-label::after {
  content: '';
  position: absolute;
  top: rem(6px);
  left: rem(3px);
  width: rem(12px);
  height: rem(12px);
}

.custom-toggle.custom-toggle-sm .custom-control-input:checked ~ .custom-control-label::after {
  left: rem(31px);
}

.custom-toggle.custom-toggle-sm .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  width: rem(16px);
}

.custom-control-label-checkbox::after {
  top: rem(3px) !important;
}

.custom-control-label-checkbox::before {
  top: 0 !important;
  width: rem(16px) !important;
  height: rem(16px) !important;
}

.custom-control-label-radio::after {
  width: rem(7.84px) !important;
  height: rem(7.84px) !important;
  top: rem(8.512px) !important;
  left: rem(6.08px) !important;
}

// DARK THEME
body.dark-theme {
  .custom-select,
  .custom-select option {
    color: _get-color(dark-theme, on-primary, text-color);
    background-color: _get-color(dark-theme, primary, sub-content-background);
  }
}
