/*
These styles were previously in src/assets/css/shards-dashboards.1.1.0.css

IMPORTANT: they are mostly an override of the bootstrap styles defined in: node_modules/bootstrap/scss/_buttons.scss
*/
@import '../typo-vars';
@import '../colors';
@import '../functions/unit-transform';

.btn {
  font-weight: $_font-weight-regular;
  font-size: $_font-size-xs;
  border: rem(1px) solid transparent;
  padding: rem(9px) rem(16px);
  line-height: 1.125;
  border-radius: rem(4px);
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

  &:hover {
    box-shadow: none;
  }
}

.btn.hover,
.btn:hover {
  cursor: pointer;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn:not([disabled], .disabled).active,
.btn:not([disabled], .disabled):active {
  background-image: none;
  box-shadow: none;
}

.btn.btn-squared {
  border-radius: 0;
}

.btn-primary {
  color: #fff;
  border-color: #00b8d8;
  background-color: #00b8d8;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00a2bf;
  border-color: #00a2bf;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(0 162 191 / 25%);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(0 162 191 / 25%),
    0 rem(3px) rem(15px) rgb(0 162 191 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-primary:not(:disabled, .disabled).active,
.btn-primary:not(:disabled, .disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #006fe6;
  border-color: #0062cc;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-secondary {
  color: #fff;
  border-color: #5a6169;
  background-color: #5a6169;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #4e545b;
  border-color: #4e545b;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(90 97 105 / 25%);
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(90 97 105 / 15%),
    0 rem(3px) rem(15px) rgb(90 97 105 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #5a6169;
  border-color: #5a6169;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-secondary:not(:disabled, .disabled).active,
.btn-secondary:not(:disabled, .disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4e545b;
  border-color: #42484e;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-success {
  color: #fff;
  border-color: #17c671;
  background-color: #17c671;
  box-shadow: none;
}

.btn-success:hover {
  color: #fff;
  background-color: #14af64;
  border-color: #14af64;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(23 198 113 / 25%);
}

.btn-success.focus,
.btn-success:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(23 198 113 / 15%),
    0 rem(3px) rem(15px) rgb(23 198 113 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #17c671;
  border-color: #17c671;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-success:not(:disabled, .disabled).active,
.btn-success:not(:disabled, .disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #14af64;
  border-color: #129857;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-info {
  color: #fff;
  border-color: #00b8d8;
  background-color: #00b8d8;
  box-shadow: none;
}

.btn-info:hover {
  color: #fff;
  background-color: #00a2bf;
  border-color: #00a2bf;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(0 184 216 / 25%);
}

.btn-info.focus,
.btn-info:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(0 184 216 / 15%),
    0 rem(3px) rem(15px) rgb(0 184 216 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-info:not(:disabled, .disabled).active,
.btn-info:not(:disabled, .disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00a2bf;
  border-color: #008da5;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-warning {
  color: #212529;
  border-color: #ffb400;
  background-color: #ffb400;
  box-shadow: none;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e6a200;
  border-color: #e6a200;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(255 180 0 / 25%);
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(255 180 0 / 15%),
    0 rem(3px) rem(15px) rgb(255 180 0 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffb400;
  border-color: #ffb400;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-warning:not(:disabled, .disabled).active,
.btn-warning:not(:disabled, .disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e6a200;
  border-color: #cc9000;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-danger {
  color: #fff;
  border-color: #c4183c;
  background-color: #c4183c;
  box-shadow: none;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ad1535;
  border-color: #ad1535;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(196 24 60 / 25%);
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(196 24 60 / 15%),
    0 rem(3px) rem(15px) rgb(196 24 60 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #c4183c;
  border-color: #c4183c;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-danger:not(:disabled, .disabled).active,
.btn-danger:not(:disabled, .disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad1535;
  border-color: #97122e;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-light {
  color: #212529;
  border-color: #fbfbfb;
  background-color: #fbfbfb;
  box-shadow: none;
}

.btn-light:hover {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(251 251 251 / 25%);
}

.btn-light.focus,
.btn-light:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(251 251 251 / 15%),
    0 rem(3px) rem(15px) rgb(251 251 251 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-light:not(:disabled, .disabled).active,
.btn-light:not(:disabled, .disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #eee;
  border-color: #e2e2e2;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-dark {
  color: #fff;
  border-color: $dark-bg-gray-color;
  background-color: $dark-bg-gray-color;
  box-shadow: none;
}

.btn-dark:hover {
  color: #fff;
  background-color: #16181b;
  border-color: #16181b;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(33 37 41 / 25%);
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(33 37 41 / 15%),
    0 rem(3px) rem(15px) rgb(33 37 41 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: $dark-bg-gray-color;
  border-color: $dark-bg-gray-color;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-dark:not(:disabled, .disabled).active,
.btn-dark:not(:disabled, .disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #16181b;
  border-color: #0a0c0d;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-white {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
  box-shadow: none;
}

.btn-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow:
    0 rem(2px) rem(10px) rgb(129 142 163 / 20%),
    0 rem(10px) rem(2px) rgb(129 142 163 / 30%);
}

.btn-white.focus,
.btn-white:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(255 255 255 / 15%),
    0 rem(3px) rem(15px) rgb(255 255 255 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-white.disabled,
.btn-white:disabled {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-white:not(:disabled, .disabled).active,
.btn-white:not(:disabled, .disabled):active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #e6e6e6;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-primary {
  background-color: transparent;
  background-image: none;
  border-color: #00b8d8;
  color: #00b8d8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(0 123 255 / 25%);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(0 123 255 / 15%),
    0 rem(3px) rem(15px) rgb(0 123 255 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #00b8d8;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-primary:not(:disabled, .disabled).active,
.btn-outline-primary:not(:disabled, .disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
}

.btn-outline-primary:not(:disabled, .disabled).active:focus,
.btn-outline-primary:not(:disabled, .disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-success {
  background-color: transparent;
  background-image: none;
  border-color: #17c671;
  color: #17c671;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #17c671;
  border-color: #17c671;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(23 198 113 / 25%);
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(23 198 113 / 15%),
    0 rem(3px) rem(15px) rgb(23 198 113 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #17c671;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-success:not(:disabled, .disabled).active,
.btn-outline-success:not(:disabled, .disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #17c671;
  border-color: #17c671;
}

.btn-outline-success:not(:disabled, .disabled).active:focus,
.btn-outline-success:not(:disabled, .disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-info {
  background-color: transparent;
  background-image: none;
  border-color: #00b8d8;
  color: #00b8d8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(0 184 216 / 25%);
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(0 184 216 / 15%),
    0 rem(3px) rem(15px) rgb(0 184 216 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00b8d8;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-info:not(:disabled, .disabled).active,
.btn-outline-info:not(:disabled, .disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00b8d8;
  border-color: #00b8d8;
}

.btn-outline-info:not(:disabled, .disabled).active:focus,
.btn-outline-info:not(:disabled, .disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-danger {
  background-color: transparent;
  background-image: none;
  border-color: #c4183c;
  color: #c4183c;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #c4183c;
  border-color: #c4183c;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(196 24 60 / 25%);
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(196 24 60 / 15%),
    0 rem(3px) rem(15px) rgb(196 24 60 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #c4183c;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-danger:not(:disabled, .disabled).active,
.btn-outline-danger:not(:disabled, .disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #c4183c;
  border-color: #c4183c;
}

.btn-outline-danger:not(:disabled, .disabled).active:focus,
.btn-outline-danger:not(:disabled, .disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-warning {
  background-color: transparent;
  background-image: none;
  color: $warning-color;
  border-color: $warning-color;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: $warning-color;
  border-color: $warning-color;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(255 180 0 / 25%);
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(255 180 0 / 15%),
    0 rem(3px) rem(15px) rgb(255 180 0 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: $warning-color;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-warning:not(:disabled, .disabled).active,
.btn-outline-warning:not(:disabled, .disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: $warning-color;
  border-color: $warning-color;
}

.btn-outline-warning:not(:disabled, .disabled).active:focus,
.btn-outline-warning:not(:disabled, .disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-link {
  font-weight: $_font-weight-regular;
  color: #00b8d8;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: $_font-size-s;
  line-height: 1.5;
  border-radius: rem(24px);
  padding-left: rem(12px);
  padding-right: rem(12px);
  display: flex;
  align-items: center;
  height: rem(34px);
}

.btn-group-md > .btn,
.btn-md {
  font-size: $_font-size-s;
  border-radius: rem(24px);
  padding-left: rem(10px);
  padding-right: rem(10px);
  display: flex;
  align-items: center;
  height: rem(30px);
}

.btn-group-sm > .btn,
.btn-sm {
  padding: rem(6.858px) rem(14px);
  font-size: rem(11px);
  line-height: 1.5;
  border-radius: rem(4px);
}

.btn-block + .btn-block {
  margin-top: rem(8px);
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: rem(-1px);
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child, .dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: rem(-1px);
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child, .dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-white:not([disabled], .disabled).active,
.btn-white:not([disabled], .disabled):active {
  box-shadow: none !important;
  background-color: #00b8d8;
  border-color: #1e8a9c7a;
  color: #fff;
}

.btn-white:not([disabled], .disabled).active + .btn,
.btn-white:not([disabled], .disabled):active + .btn {
  border-left: rem(1px) solid #1e8a9c7a;
}

.btn.btn-white {
  border: rem(1px) solid #e1e5eb;
  color: #3d5170;
}

.btn.btn-white:not([disabled], .disabled).active-primary.active,
.btn.btn-white:not([disabled], .disabled).active-primary:active {
  background-color: #00b8d8;
  border-color: #00b8d8;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-primary.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-primary:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-secondary.active,
.btn.btn-white:not([disabled], .disabled).active-secondary:active {
  background-color: #5a6169;
  border-color: #5a6169;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-secondary.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-secondary:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-success.active,
.btn.btn-white:not([disabled], .disabled).active-success:active {
  background-color: #17c671;
  border-color: #17c671;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-success.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-success:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-info.active,
.btn.btn-white:not([disabled], .disabled).active-info:active {
  background-color: #00b8d8;
  border-color: #00b8d8;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-info.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-info:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-warning.active,
.btn.btn-white:not([disabled], .disabled).active-warning:active {
  background-color: #ffb400;
  border-color: #ffb400;
  color: #212529;
}

.btn.btn-white:not([disabled], .disabled).active-warning.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-warning:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-danger.active,
.btn.btn-white:not([disabled], .disabled).active-danger:active {
  background-color: #c4183c;
  border-color: #c4183c;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-danger.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-danger:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-light.active,
.btn.btn-white:not([disabled], .disabled).active-light:active {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  color: #212529;
}

.btn.btn-white:not([disabled], .disabled).active-light.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-light:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-dark.active,
.btn.btn-white:not([disabled], .disabled).active-dark:active {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-dark.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-dark:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-salmon.active,
.btn.btn-white:not([disabled], .disabled).active-salmon:active {
  background-color: #ff4169;
  border-color: #ff4169;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-salmon.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-salmon:active + .btn {
  border-left: rem(1px) solid red;
}

.btn.btn-white:not([disabled], .disabled).active-royal-blue.active,
.btn.btn-white:not([disabled], .disabled).active-royal-blue:active {
  background-color: #674eec;
  border-color: #674eec;
  color: #fff;
}

.btn.btn-white:not([disabled], .disabled).active-royal-blue.active + .btn,
.btn.btn-white:not([disabled], .disabled).active-royal-blue:active + .btn {
  border-left: rem(1px) solid red;
}

.btn-salmon {
  color: #fff;
  border-color: #ff4169;
  background-color: #ff4169;
  box-shadow: none;
}

.btn-salmon:hover {
  color: #fff;
  background-color: #ff2855;
  border-color: #ff2855;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(255 65 105 / 25%);
}

.btn-salmon.focus,
.btn-salmon:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(255 65 105 / 15%),
    0 rem(3px) rem(15px) rgb(255 65 105 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-salmon.disabled,
.btn-salmon:disabled {
  background-color: #ff4169;
  border-color: #ff4169;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-salmon:not(:disabled, .disabled).active,
.btn-salmon:not(:disabled, .disabled):active,
.show > .btn-salmon.dropdown-toggle {
  color: #fff;
  background-color: #ff2855;
  border-color: #ff0e41;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-accent {
  color: #fff;
  border-color: #00b8d8;
  background-color: #00b8d8;
  box-shadow: none;
}

.btn-accent:hover {
  color: #fff;
  background-color: #006fe6;
  border-color: #006fe6;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(0 123 255 / 25%);
}

.btn-accent.focus,
.btn-accent:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(0 123 255 / 15%),
    0 rem(3px) rem(15px) rgb(0 123 255 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%);
}

.btn-accent.disabled,
.btn-accent:disabled {
  background-color: #00b8d8;
  border-color: #00b8d8;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-accent:not(:disabled, .disabled).active,
.btn-accent:not(:disabled, .disabled):active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #006fe6;
  border-color: #0062cc;
  background-image: none;
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-outline-salmon {
  background-color: transparent;
  background-image: none;
  border-color: #ff4169;
  color: #ff4169;
}

.btn-outline-salmon:hover {
  color: #fff;
  background-color: #ff4169;
  border-color: #ff4169;
  box-shadow:
    0 rem(5px) rem(15px) rgb(0 0 0 / 5%),
    0 rem(4px) rem(10px) rgb(255 65 105 / 25%);
}

.btn-outline-salmon.focus,
.btn-outline-salmon:focus {
  box-shadow:
    0 0 0 rem(3px) rgb(255 65 105 / 15%),
    0 rem(3px) rem(15px) rgb(255 65 105 / 20%),
    0 rem(2px) rem(5px) rgb(0 0 0 / 10%) !important;
}

.btn-outline-salmon.disabled,
.btn-outline-salmon:disabled {
  color: #ff4169;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-salmon:not(:disabled, .disabled).active,
.btn-outline-salmon:not(:disabled, .disabled):active,
.show > .btn-outline-salmon.dropdown-toggle {
  color: #fff;
  background-color: #ff4169;
  border-color: #ff4169;
}

.btn-outline-salmon:not(:disabled, .disabled).active:focus,
.btn-outline-salmon:not(:disabled, .disabled):active:focus,
.show > .btn-outline-salmon.dropdown-toggle:focus {
  box-shadow: inset 0 rem(3px) rem(5px) rgb(0 0 0 / 12.5%) !important;
}

.btn-group-sm i {
  transform: scale(1.3);
}
