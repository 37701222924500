/* Responsive sizes */
$spacing-size-none: 0;
$spacing-size-default: 1em;
$spacing-size-025: 0.25em;
$spacing-size-05: 0.5em;
$spacing-size-075: $spacing-size-05 + $spacing-size-025;
$spacing-size-1: $spacing-size-default;
$spacing-size-15: $spacing-size-1 + $spacing-size-05;
$spacing-size-2: 2em;
$spacing-size-25: $spacing-size-2 + $spacing-size-05;
$spacing-size-3: 3em;
$spacing-size-35: $spacing-size-3 + $spacing-size-05;
$spacing-size-4: 4em;

/* Responsive texts */
/* stylelint-disable unit-disallowed-list */
$text-size-none: 0;
$text-size-default: 0.25rem;
$text-size-010: 0.1rem;
$text-size-015: 0.15rem;
$text-size-020: 0.2rem;
$text-size-025: $text-size-default;
$text-size-03: $text-size-010 + $text-size-020;
$text-size-035: $text-size-020 + $text-size-015;
$text-size-04: 0.4rem;
$text-size-05: 0.5rem;
$text-size-075: $text-size-025 + $text-size-05;
$text-size-1: 1rem;
$text-size-15: $text-size-1 + $text-size-05;
$text-size-2: 2rem;
$text-size-25: $text-size-2 + $text-size-05;
/* stylelint-enable unit-disallowed-list */
